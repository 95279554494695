import {
  Box,
  CircularProgress,
  Grow,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { DividerSVG } from "../assets/dividerSVG";
import { SuccessSVG } from "../assets/successSVG";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AppContainer } from "./Container";
import { AppPaper } from "./Paper";
import { useParams } from "react-router-dom";
import Axios from "axios";

export const AppPaymentSuccess = () => {
  const { t } = useTranslation();
  const { cartId } = useParams();
  const [cart, setCart] = useState();

  useEffect(() => {
    Axios({
      url:
        "https://us-central1-lazywaitposcordova.cloudfunctions.net/app/paytabs/v2/getCart",
      headers: { "content-type": "application/json" },
      method: "POST",
      data: { cartId: cartId },
    })
      .then((res) => {
        return setCart(res.data);
      })
      .catch((error) => console.log("Error @ PaymentSuccess", error));
  }, [cartId]);

  return (
    <AppContainer>
      <AppPaper>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box marginTop="45px">
            <DividerSVG color="#098A77" />
          </Box>
          <Grow in timeout={300}>
            <Box marginTop="55px">
              <SuccessSVG />
            </Box>
          </Grow>
          <Box marginTop="25px" color="#098A77">
            <Typography variant="h4">{t("payment_success")}</Typography>
          </Box>
          <Box
            maxWidth="500px"
            textAlign="center"
            marginTop="30px"
            color="#484848"
          >
            <Typography variant="h6">
              {t("payment_success_message")}.
            </Typography>
          </Box>
          <Box margin="35px 0 35px 0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="396"
              height="1"
              viewBox="0 0 396 1"
            >
              <line
                id="Line_9"
                data-name="Line 9"
                x2="396"
                transform="translate(0 0.5)"
                fill="none"
                stroke="#707070"
                strokeWidth="1"
                strokeDasharray="5"
                opacity="0.27"
              />
            </svg>
          </Box>
          <Box>
            <Typography variant="h5">{t("success_invoice")}</Typography>
          </Box>
          <Box>
            {!cart ? (
              <Box
                position="relative"
                m="32px"
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grow in>
                <Box m={4}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell align="right">{t("purchased_item")}</TableCell>
                          <TableCell align="right">
                            {cart.items[0].name.toUpperCase()} Plan
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">{t("subscription_type")}</TableCell>
                          <TableCell align="right">
                            {cart.subscriptionType}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">{t("purchased_amount")}</TableCell>
                          <TableCell align="right">
                            {cart.amount} {cart.currency}
                          </TableCell>
                        </TableRow>
                        <TableRow style={{backgroundColor: "rgb(9 138 119 / 45%)"}}>
                          <TableCell align="right">{t("license_id")}</TableCell>
                          <TableCell align="right">{cart.licenseId}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">{t("transaction_reference")}</TableCell>
                          <TableCell align="right">{cart.tranRef}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grow>
            )}
          </Box>
        </Box>
      </AppPaper>
    </AppContainer>
  );
};
