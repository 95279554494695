import React from 'react'

export const Terminal = ({ color }) => <svg id="Group_17736" data-name="Group 17736" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="65.667" height="56.307" viewBox="0 0 65.667 56.307">
    <defs>
        <clipPath id="clip-path">
            <rect id="Rectangle_764" data-name="Rectangle 764" width="65.667" height="56.307" fill={color} />
        </clipPath>
    </defs>
    <g id="Group_17735" data-name="Group 17735" clipPath="url(#clip-path)">
        <path id="Path_2041" data-name="Path 2041" d="M65.667,4.034V52.255c-.726,2.952-2.811,4.06-5.672,4.052-18.209-.051-36.417-.024-54.626-.032a7.2,7.2,0,0,1-1.778-.156A4.635,4.635,0,0,1,.006,51.351Q-.008,43.785,0,36.22q0-15.548,0-31.1a5.089,5.089,0,0,1,1-3.3A5,5,0,0,1,5.243,0Q28.549.02,51.855.008c2.907,0,5.813.012,8.72,0a5.041,5.041,0,0,1,3.637,1.31,5.556,5.556,0,0,1,1.456,2.72m-4.756,7.789H4.736V51.569H60.911ZM17.58,5.881a2.333,2.333,0,1,0-4.666-.035,2.333,2.333,0,0,0,4.666.035m-7.042.005a2.328,2.328,0,1,0-4.656-.045,2.328,2.328,0,0,0,4.656.045" transform="translate(0 0)" fill={color} />
        <path id="Path_2042" data-name="Path 2042" d="M73.9,89l-6.512-2.786c-1.549-.663-3.093-1.341-4.653-1.979a.719.719,0,0,1-.524-.81c.032-.872.009-1.745.009-2.714.273.111.465.18.649.265,4.618,2.112,9.231,4.232,13.858,6.325a.794.794,0,0,1,.562.908c-.055.74.2,1.638-.142,2.181-.323.518-1.232.676-1.89.978q-6.138,2.814-12.28,5.62c-.209.1-.423.179-.747.315,0-1.1-.021-2.108.024-3.118.006-.141.312-.311.509-.4Q68,91.543,73.241,89.312c.188-.08.371-.173.661-.31" transform="translate(-50.991 -66.156)" fill={color} />
        <rect id="Rectangle_763" data-name="Rectangle 763" width="8.144" height="3.068" transform="translate(33.402 21.323)" fill={color} />
    </g>
</svg>

