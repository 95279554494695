import React from 'react'

export const Yearly = ({color}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="57.182" height="56.028" viewBox="0 0 57.182 56.028">
        <g id="Group_17738" data-name="Group 17738">
            <g id="Group_17740" data-name="Group 17740">
                <path id="Path_2043" data-name="Path 2043" d="M0,47.888V9.914a1.111,1.111,0,0,0,.083-.2A4.551,4.551,0,0,1,3.492,5.981a13.931,13.931,0,0,1,1.659-.268c0-.619-.014-1.232,0-1.843A3.745,3.745,0,0,1,9.5.069a3.674,3.674,0,0,1,3.119,3.306c.046.79.008,1.585.008,2.379h5.7c0-.807-.04-1.586.008-2.359a3.742,3.742,0,0,1,7.465.454c.013.627,0,1.255,0,1.89h5.682c0-.844-.056-1.647.011-2.44a3.71,3.71,0,0,1,7.379.038c.061.79.01,1.589.01,2.409h5.671c0-.778-.038-1.521.007-2.26a3.714,3.714,0,0,1,7.393-.228,22.274,22.274,0,0,1,.015,2.455,14.341,14.341,0,0,1,1.671.247,4.655,4.655,0,0,1,3.543,4.75q-.007,18.313-.035,36.626a9.314,9.314,0,0,1-.121,1.556,8.467,8.467,0,0,1-8.514,7.127c-2.029.017-4.057,0-6.086,0q-16.805,0-33.611-.007a8.234,8.234,0,0,1-8-5.028,17.362,17.362,0,0,1-.81-3.1m53.368-32.1H3.82c-.011.133-.024.225-.024.316q0,15.688.007,31.376a4.651,4.651,0,0,0,4.832,4.77q19.959.019,39.918-.014a5.209,5.209,0,0,0,2.15-.472,4.525,4.525,0,0,0,2.685-4.4q.012-15.576,0-31.153c0-.129-.013-.258-.022-.426" transform="translate(0 0)" fill={color} />
            </g>
        </g>
        <rect id="Rectangle_766" data-name="Rectangle 766" width="34" height="17" rx="2" transform="translate(12 26)" fill={color} />
    </svg>
}
