import Axios from "axios";
import publicIp from "public-ip";
import CONSTANTS from "./Constants";
export const getClientInfo = (clientId) => {
  let headers = {
    Accept: "application/json, text/plain",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  return new Promise((resolve, reject) => {
    fetch(
      `https://us-central1-lazywaitposcordova.cloudfunctions.net/app/getClientLicense?client_id=${clientId}`,
      { headers }
    )
      .then((res) => res.json())
      .then((result) => resolve(result))
      .catch((error) => {
        throw new Error(error);
      });
  });
};

export const createPaymentPage = async (request) => {
  try {
    const response = await Axios({
      method: "post",
      url: CONSTANTS.CREATE_PAGE_URL,
      data: request,
      headers: { "Content-Type": "application/json" },
    });
    if (
      response &&
      response.data &&
      response.data.hasOwnProperty("redirectURL")
    ) {
      window.location.replace(response.data.redirectURL);
    } else {
      console.log("Error @ placeOrder Axios then", response);
      throw new Error("Error @ placeOrder Axios then");
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

export const createClient = async (request) =>
  POST(CONSTANTS.CREATE_CLIENT_URL, request);

export const createBranch = async (request) =>
  POST(CONSTANTS.CREATE_BRANCH_URL, request);

export const updateBranch = async (request) =>
  POST(CONSTANTS.UPDATE_BRANCH_URL, request);

export const createUser = async (request) =>
  POST(CONSTANTS.CREATE_USER_URL, request);

export const createLicense = async (request) =>
  POST(CONSTANTS.CREATE_LICENSE_URL, request);

export const createAccount = async (request) =>
  POST(CONSTANTS.CREATE_ACCOUNT_URL, request);

export const VerifyAccount = async (email) => {
  return await Axios({
    method: "get",
    url: CONSTANTS.VERIFY_ACCOUNT_URL + "?email=" + email,
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.data;
  });
};

export const getClientIp = async () =>
  await publicIp.v4({
    fallbackUrls: ["https://ifconfig.co/ip"],
  });

export const getAllCountries = async () => {
  try {
    let headers = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const response = await fetch(CONSTANTS.GET_COUNTRY_CODES_URL, { headers });
    return response.json();
  } catch (error) {
    console.log("Error @ getAllCountries", error);
  }
};

export const getAllCategories = async () => {
  try {
    let headers = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const response = await fetch(CONSTANTS.GET_CATEGORIES_URL, { headers });
    return response.json();
  } catch (error) {
    console.log("Error @ getAllCategories", error);
  }
};

export const getClientContract = async () => {
  try {
    let headers = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const response = await fetch(CONSTANTS.GET_CLIENT_CONTRACT_URL, {
      headers,
    });
    return response.json();
  } catch (error) {
    console.log("Error @ getClientContract", error);
  }
};

export const getDanatAlkhaleejContract = async () => {
  try {
    let headers = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const response = await fetch(CONSTANTS.GET_DANAT_ALKHALEEJ_URL, {
      headers,
    });
    return response.json();
  } catch (error) {
    console.log("Error @ getClientContract", error);
  }
};

export const getMarketplaceContract = async () => {
  try {
    let headers = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const response = await fetch(CONSTANTS.GET_MARKETPLACE_URL, { headers });
    return response.json();
  } catch (error) {
    console.log("Error @ getClientContract", error);
  }
};

export const getAllSubCategories = async (category_id) => {
  try {
    let headers = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const response = await fetch(
      CONSTANTS.GET_SUB_CATEGORIES_URL + "?category_id=" + category_id,
      { headers }
    );
    return response.json();
  } catch (error) {
    console.log("Error @ getAllSubCategories", error);
  }
};

export const hadTrial = async (email, client_id) => {
  try {
    let headers = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const response = await fetch(CONSTANTS.HAD_TRIAL_URL + "?email=" + email + "&client_id=" + client_id, {
      headers,
    });
    return response.json();
  } catch (error) {
    console.log("Error @ hadTrial", error);
  }
};

export const GetPromoCode = async (code, plan_id, plan_type) => {
  try {
    let headers = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const response = await fetch(
      CONSTANTS.GET_PROMO_CODE_URL +
      `?code=${code}&plan_id=${plan_id}&plan_type=${plan_type}`,
      { headers }
    );
    return response.json();
  } catch (error) {
    console.log("Error @ GetPromoCode", error);
  }
};

export const getPaymentToken = (request) => {
  return new Promise((resolve, reject) => {
    request = { clientKey: CONSTANTS.PAYTABS_CLIENT_KEY, ...request };
    var xhr = new XMLHttpRequest();
    request = JSON.stringify(request);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (this.status === 200) {
          var resp;
          try {
            resp = JSON.parse(xhr.responseText ? xhr.responseText : "{}");
            if (resp == null) {
              resp = {
                status: xhr.status,
                error: true,
                errorText: xhr.statusText,
              };
            }
            resolve(resp);
          } catch (e) {
            console.log("Error @ xhr", e);
          }
        } else {
          resp = JSON.parse(xhr.responseText ? xhr.responseText : "{}");
          if (resp == null) {
            resp = {
              status: xhr.status,
              error: true,
              errorText: xhr.statusText,
            };
          }
          resolve(resp);
        }
      }
    };
    xhr.open("POST", "https://secure.paytabs.sa/payment/tokenise", true);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.send(request);
  });
};

export const getPlan = async (planName) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return new Promise((resolve, reject) => {
    fetch(
      `https://us-central1-lazywaitposcordova.cloudfunctions.net/app/plan?plan_id=${plansIDs[planName.toLowerCase()]
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => console.log("error @ getPlan", error));
  });
};

export const getBranch = (clientId, branchId) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    clientId: clientId,
    branchId: branchId,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://us-central1-lazywaitposcordova.cloudfunctions.net/app/paytabs/v2/getBranch",
    requestOptions
  );
};

export const sendMailToSupport = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    from: "support@cloudapps.net.sa",
    to: CONSTANTS.TO_SUPPORT_EMAIL,
    subject: `DirectPayment: Card entry error by ${data.first_name} ${data.last_name}`,
    html: `<div> <h4>Dear customer support team</h4> <p>The following client has difficulties entering his card information:</p> <table> <tr> <td><b>Name</b></td> <td>${data.first_name} ${data.last_name}</td> </tr> <tr> <td><b>Email</b></td> <td>${data.owner_email}</td> </tr> <tr> <td><b>Phone</b></td> <td>${data.callingCode} ${data.phone}</td></tr><tr><td><b>Client ID</b></td> <td>${data.clientId}</td></tr></tr><tr><td><b>Branch ID</b></td> <td>${data.branchId}</td></tr><tr><td><b>Message</b></td> <td>${data.errorMessage}</td></tr></table></div>`,
    attachments: [],
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    "https://us-central1-lazywaitposcordova.cloudfunctions.net/app/email",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

const plansIDs = {
  free: "TalxGySrrQlNILJVjyEb",
  starter: "DbzXU7bLzcaDfAzsYn2e",
  plus: "bKs56wnjwMfkJ3RyQoD8",
  pro: "79CccpdNavBfAPraMuxP",
  lite: "ealXhxYIMHSa5XKK0TAz",
  soft: "wwjmH2Y7P3oJXg2GoLV7",
  danatalkhaleej: "kNVbPm9Hw2Do3UNHEHje",
  plus2years: "aoA7sXa6fbPgEVt4Ob36",
  test: "E5ZIzm36wK7J5DZf607F",
};

async function POST(url, payload) {
  try {
    let headers = {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });
    const response = await res.json();
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
}
