const baseURL = 'https://us-central1-lazywaitposcordova.cloudfunctions.net/app';
// when running in development mode, ngrok URL has to be updated
const ngrokBaseURL = 'https://us-central1-lazywaitposcordova.cloudfunctions.net/app';
const CONSTANTS = {
	production: {
		RETURN_URL: `${baseURL}/paytabs/v2/createLicense`,
		CREATE_PAGE_URL: `${baseURL}/paytabs/v2/createPage`,
		CREATE_CLIENT_URL: `${baseURL}/createClient`,
		CREATE_BRANCH_URL: `${baseURL}/createBranch`,
		UPDATE_BRANCH_URL: `${baseURL}/updateBranch`,
		CREATE_USER_URL: `${baseURL}/createUser`,
		CREATE_LICENSE_URL: `${baseURL}/createLicense`,
		GET_BRANCH_URL: `${baseURL}/paytabs/v2/getBranch`,
		GET_CATEGORIES_URL: `${baseURL}/clients_categories`,
		GET_COUNTRY_CODES_URL: `${baseURL}/country_codes`,
		GET_CLIENT_CONTRACT_URL: `${baseURL}/client_contract`,
		GET_DANAT_ALKHALEEJ_URL: `${baseURL}/danatAlkhaleej_contract`,
		GET_MARKETPLACE_URL: `${baseURL}/marketplace_contract`,
		GET_SUB_CATEGORIES_URL: `${baseURL}/clients_sub_categories`,
		GET_PROMO_CODE_URL: `${baseURL}/plan/promo_code`,
		CREATE_ACCOUNT_URL: `${baseURL}/auth/register/email`,
		VERIFY_ACCOUNT_URL: `${baseURL}/auth/isUserVerified`,
		HAD_TRIAL_URL: `${baseURL}/migration/clients/hadTrial`,
		PAYTABS_CLIENT_KEY: 'CVKMT7-RDVPH9-9GMKN6-MQKM2H',
		TO_SUPPORT_EMAIL: 'support@cloudapps.net.sa',
	},
	development: {
		RETURN_URL: `${ngrokBaseURL}/paytabs/v2/createLicense`,
		CREATE_PAGE_URL: `${ngrokBaseURL}/paytabs/v2/createPage`,
		CREATE_CLIENT_URL: `${ngrokBaseURL}/createClient`,
		CREATE_BRANCH_URL: `${ngrokBaseURL}/createBranch`,
		UPDATE_BRANCH_URL: `${baseURL}/updateBranch`,
		CREATE_USER_URL: `${ngrokBaseURL}/createUser`,
		CREATE_LICENSE_URL: `${ngrokBaseURL}/createLicense`,
		GET_BRANCH_URL: `${ngrokBaseURL}/paytabs/v2/getBranch`,
		GET_CATEGORIES_URL: `${ngrokBaseURL}/clients_categories`,
		// GET_COUNTRY_CODES_URL:`${ngrokBaseURL}/country_codes`,
		// GET_CLIENT_CONTRACT_URL:`${ngrokBaseURL}/client_contract`,
		GET_DANAT_ALKHALEEJ_URL: `${baseURL}/danatAlkhaleej_contract`,
		GET_MARKETPLACE_URL: `${baseURL}/marketplace_contract`,
		GET_COUNTRY_CODES_URL: `${baseURL}/country_codes`,
		GET_CLIENT_CONTRACT_URL: `${baseURL}/client_contract`,
		GET_SUB_CATEGORIES_URL: `${ngrokBaseURL}/clients_sub_categories`,
		GET_PROMO_CODE_URL: `${ngrokBaseURL}/plan/promo_code`,
		CREATE_ACCOUNT_URL: `${ngrokBaseURL}/auth/register/email`,
		VERIFY_ACCOUNT_URL: `${ngrokBaseURL}/auth/isUserVerified`,
		HAD_TRIAL_URL: `${ngrokBaseURL}/hadTrial`,
		PAYTABS_CLIENT_KEY: 'C2KMT7-RD7NH9-VT2QBH-BTR66G',
		TO_SUPPORT_EMAIL: 'abbas.aleid@cloudapps.net.sa',
	},
};

export default CONSTANTS[process.env.NODE_ENV];
