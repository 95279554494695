import { isCardMonthValid, isCardNumberValid, isCardYearValid, isCVVNumberValid, isEmpty } from './utils';

export function validateCustomerInfo(customerInfo, setErrors, customer_exist) {
	let errs = {};
	if (isEmpty(customerInfo.first_name)) errs.first_name = true;
	if (isEmpty(customerInfo.last_name)) errs.last_name = true;
	if (isEmpty(customerInfo.owner_email) && !customer_exist) errs.owner_email = true;
	if (isEmpty(customerInfo.country)) errs.country = true;
	if (isEmpty(customerInfo.state)) errs.state = true;
	if (isEmpty(customerInfo.city)) errs.city = true;
	if (isEmpty(customerInfo.street1)) errs.street1 = true;
	if (isEmpty(customerInfo.callingCode)) errs.callingCode = true;
	if (isEmpty(customerInfo.phone)) errs.phone = true;
	if (isEmpty(customerInfo.zipCode)) errs.zipCode = true;
	if (isEmpty(customerInfo.subscriptionType)) errs.subscriptionType = true;
	if (isEmpty(customerInfo.ip)) errs.ip = true;
	if ((isEmpty(customerInfo.password) || (customerInfo && customerInfo.password < 7)) && !customer_exist) errs.password = true;

	console.log('in: ', errs);
	setErrors(errs);

	return Object.keys(errs).length === 0;
}

export function validateBrandInfo(brandInfo, setErrors) {
	let errs = {};
	if (isEmpty(brandInfo.brand_name)) errs.brand_name = true;
	if (isEmpty(brandInfo.branch_name)) errs.branch_name = true;
	if (isEmpty(brandInfo.callingCode)) errs.callingCode = true;
	if (isEmpty(brandInfo.phone)) errs.phone = true;
	if (isEmpty(brandInfo.category)) errs.category = true;
	if (isEmpty(brandInfo.sub_category)) errs.sub_category = true;

	console.log('in: ', errs);
	setErrors(errs);

	return Object.keys(errs).length === 0;
}

export function validateCardInfo(cardInfo, setErrors) {
	let errs = {};
	if (!isCardNumberValid(cardInfo.number)) errs.number = true;
	if (!isCardMonthValid(cardInfo.month)) errs.month = true;
	if (!isCardYearValid(cardInfo.year)) errs.year = true;
	if (!isCVVNumberValid(cardInfo.cvv)) errs.cvv = true;

	console.log('in: ', errs);
	setErrors(errs);

	return Object.keys(errs).length === 0;
}
