import { Box, CircularProgress, Grow, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import exclamationMarkSVG from '../assets/icons/exclamation_mark.svg';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useTranslation } from 'react-i18next';
import { getPlan } from '../api/API';

export const AppPlan = ({ setPrice, subscriptionType }) => {
	const { planName, clientId, branchId } = useParams();
	const { t } = useTranslation();
	const [plan, setPlan] = useState();

	useEffect(() => {
		(async () => {
			const plan = await getPlan(planName !== undefined ? planName : 'plus');
			console.log(subscriptionType);
			setPlan(plan);
			setPrice(subscriptionType === 'Annual' ? plan.yearly_price : plan.monthly_price);
		})();
	}, [setPrice, planName, subscriptionType]);

	return plan === undefined ? (
		progressIndicator
	) : (
		<Grow in>
			<Box borderRadius="6px" padding="12px" marginTop="12.5px" border="3.5px solid #12786B" display="flex" flexDirection="column">
				<Box display="flex" alignItems="center" flexDirection="row">
					<Box flexGrow={1} color="#484848">
						<Typography variant="h4">
							{t('lang') === 'en' ? (plan.enDisplayName ? plan.enDisplayName : plan.en) : plan.arDisplayName ? plan.arDisplayName : plan.ar}
						</Typography>
					</Box>
					<Box>
						<Link to={clientId && branchId ? `/details/${plan.en.toLowerCase()}/${clientId}/${branchId}` : `/details/${plan.en.toLowerCase()}`}>
							<img alt="Exclamation Mark Button" src={exclamationMarkSVG} />
						</Link>
					</Box>
				</Box>

				{plan.yearly_price ? (
					<Box color="#9B9B9B">
						<Typography variant="body1">
							{t('yearly_price')}: {plan.yearly_price} {t('currency')}
						</Typography>
						{/* <Typography variant="body1">
              {t("monthly_price")}: {plan.monthly_price} {t("currency")}
            </Typography> */}
						<Typography variant="body1">
							{t('terminals')}: {plan.allowed_terminals}
						</Typography>
					</Box>
				) : (
					<Box color="#9B9B9B">
						<Typography variant="body1">
							{t('free_price')}: 0 {t('currency')}
						</Typography>
						<Typography variant="body1">{t('14days')}</Typography>
						<Typography variant="body1">
							{t('terminals')}: {plan.allowed_terminals}
						</Typography>
					</Box>
				)}

				<Box display="flex" flexDirection="column" alignItems="flex-end">
					<Box display="flex" flexDirection="row">
						<Box m="3px">
							<OpenInNewIcon fontSize="small" style={{ color: '#12786B' }} />
						</Box>
						<Link to={clientId && branchId ? `/plans/${planName}/${clientId}/${branchId}` : '/plans'}>
							<Box m="1px" color="#12786B">
								<Typography style={{ textDecoration: 'underline' }} variant="button">
									{t('more_subscriptions')}
								</Typography>
							</Box>
						</Link>
					</Box>
				</Box>
			</Box>
		</Grow>
	);
};

const progressIndicator = (
	<Box position="relative" m="32px" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
		<CircularProgress />
	</Box>
);
