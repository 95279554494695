export const CheckMark = () => (
	<svg id="Component_20_52" data-name="Component 20 – 52" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
		<circle id="Ellipse_137" data-name="Ellipse 137" cx="10" cy="10" r="10" fill="#12786b" />
		<path
			id="Path_1981"
			data-name="Path 1981"
			d="M6324.714,3629.314l2.511,2.845,1.959-1.851,3.369-3.184"
			transform="translate(-6318.214 -3619.625)"
			fill="none"
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
		/>
	</svg>
);

export const GradeOutCheckMark = () => (
	<svg id="Component_20_52" data-name="Component 20 – 52" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
		<circle id="Ellipse_137" data-name="Ellipse 137" cx="10" cy="10" r="10" fill="#82878d" />
		<path
			id="Path_1981"
			data-name="Path 1981"
			d="M6324.714,3629.314l2.511,2.845,1.959-1.851,3.369-3.184"
			transform="translate(-6318.214 -3619.625)"
			fill="none"
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
		/>
	</svg>
);
