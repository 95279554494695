export const SuccessSVG = () => <svg xmlns="http://www.w3.org/2000/svg" width="216.989" height="121" viewBox="0 0 216.989 121">
    <g id="Group_17733" data-name="Group 17733" transform="translate(-597 -404)">
        <path id="Path_2039" data-name="Path 2039" d="M85.1,56.424a12.827,12.827,0,0,0-4.178.707c.006-.235.017-.472.017-.707A25.778,25.778,0,0,0,31.611,45.941,19.993,19.993,0,1,0,19.994,82.2H85.1a12.89,12.89,0,0,0,0-25.779" transform="translate(716 395.797)" fill="#f3f3f3" />
        <circle id="Ellipse_148" data-name="Ellipse 148" cx="60.5" cy="60.5" r="60.5" transform="translate(656 404)" fill="#149885" />
        <path id="ic_done_24px" d="M20.794,38.524,7.748,25.479,3.4,29.827,20.794,47.221,58.067,9.948,53.718,5.6Z" transform="translate(685.6 438.4)" fill="#fff" />
        <path id="Path_2027" data-name="Path 2027" d="M85.1,56.424a12.827,12.827,0,0,0-4.178.707c.006-.235.017-.472.017-.707A25.778,25.778,0,0,0,31.611,45.941,19.993,19.993,0,1,0,19.994,82.2H85.1a12.89,12.89,0,0,0,0-25.779" transform="translate(606 436.797)" fill="#f3f3f3" />
        <path id="Path_2038" data-name="Path 2038" d="M85.1,56.424a12.827,12.827,0,0,0-4.178.707c.006-.235.017-.472.017-.707A25.778,25.778,0,0,0,31.611,45.941,19.993,19.993,0,1,0,19.994,82.2H85.1a12.89,12.89,0,0,0,0-25.779" transform="translate(597 431.797)" fill="#fff" />
    </g>
</svg>

