import {
  Box,
  CircularProgress,
  Grow,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { DividerSVG } from "../assets/dividerSVG";
import { SuccessSVG } from "../assets/successSVG";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AppContainer } from "./Container";
import { AppPaper } from "./Paper";
import { useParams } from "react-router-dom";
import Axios from "axios";

export const AppTrialCreated = () => {
  const { t } = useTranslation();


  return (
    <AppContainer>
      <AppPaper>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5">{t("trial_creation_success")}</Typography>
          <Typography variant="p">{t("check_email")}</Typography>


          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div>
              <h3>Download Now: Available in all these markets:</h3>
            </div>
            <div>
              <a href="https://apps.apple.com/sa/app/pos-point-of-sale-lazywait-5/id1532876164" target="_blank">Apple</a>
            </div>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.cloudapps.pos5&hl=en&gl=US" target="_blank">Android</a>
            </div>
            <div>
              <a href="https://appgallery.huawei.com/#/app/C103398635" target="_blank">Huawei</a>
            </div>
            <div>
              <a href="https://www.amazon.com/LazyWait-POS-Point-of-Sale/dp/B09HQPGPQC/ref=sr_1_2?dchild=1&qid=1633608539&refinements=p_4%3ACloudApps+LLC&s=mobile-apps&search-type=ss&sr=1-2" target="_blank">Amazon</a>
            </div>
          </div>
          <br />
          <br />
        </Box>
      </AppPaper>
    </AppContainer>
  );
};
