import { Grid, Box, CircularProgress, Typography } from "@material-ui/core"

export const AppPayment = () => {

    return (
        <Grid style={{padding:24}}>
            <Typography variant="h5">Processing payment</Typography>
            {progressIndicator}
        </Grid>
    )

}

const progressIndicator = <Box
    position="relative"
    m="32px"
    display="flex"
    flexDirection="row"
    flexWrap="wrap"
    justifyContent="center">
    <CircularProgress />
</Box>;