export const Page404SVG = () => <svg id="Group_17785" data-name="Group 17785" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="251.912" height="94.421" viewBox="0 0 251.912 94.421">
    <defs>
        <clipPath id="clip-path">
            <rect id="Rectangle_751" data-name="Rectangle 751" width="249.722" height="94.421" fill="none" />
        </clipPath>
        <clipPath id="clip-path-2">
            <rect id="Rectangle_750" data-name="Rectangle 750" width="249.722" height="94.42" fill="none" />
        </clipPath>
        <clipPath id="clip-path-3">
            <rect id="Rectangle_749" data-name="Rectangle 749" width="79.006" height="56.613" fill="none" />
        </clipPath>
    </defs>
    <path id="Path_2061" data-name="Path 2061" d="M290.083,93.29a3.576,3.576,0,0,0-1.163.2c0-.065.005-.13.005-.2a7.179,7.179,0,0,0-13.738-2.919,5.568,5.568,0,1,0-3.235,10.1h18.131a3.59,3.59,0,1,0,0-7.179" transform="translate(-43.075 -15.521)" fill="#fff" />
    <path id="Path_2060" data-name="Path 2060" d="M33.593,40.821a5.064,5.064,0,0,0-1.649.279c0-.093.007-.186.007-.279a10.176,10.176,0,0,0-19.472-4.138A7.892,7.892,0,1,0,7.893,51h25.7a5.088,5.088,0,0,0,0-10.176" transform="translate(0 -6.808)" fill="#fff" />
    <g id="Group_17784" data-name="Group 17784" transform="translate(2.189)">
        <g id="Group_17695" data-name="Group 17695" clipPath="url(#clip-path)">
            <g id="Group_17694" data-name="Group 17694" transform="translate(0 0)">
                <g id="Group_17693" data-name="Group 17693" clipPath="url(#clip-path-2)">
                    <path id="Path_2007" data-name="Path 2007" d="M68.6,76.471a5.61,5.61,0,0,0-2.462.571,8.361,8.361,0,0,0-11.484-5.259A10.174,10.174,0,0,0,36.3,75.661,6.674,6.674,0,0,0,25.926,79.5a4.181,4.181,0,1,0-.9,8.262H68.6a5.644,5.644,0,0,0,0-11.289" transform="translate(-3.439 -11.122)" fill="#fff" />
                    <path id="Path_2008" data-name="Path 2008" d="M27,90.907H39.311V69.955H3.821V67.466l36.93-65.74H63.407V66.811H76.373V55.418H78.86V79.645H76.373v-9.69H63.407V90.907h9.036V93.4H27Zm12.311-24.1V11.678L8.012,66.811Z" transform="translate(-0.63 -0.285)" fill="#ef5350" />
                    <path id="Path_2009" data-name="Path 2009" d="M138.711,91.586a5.61,5.61,0,0,1,2.462.571A8.361,8.361,0,0,1,152.657,86.9a10.174,10.174,0,0,1,18.351,3.878,6.674,6.674,0,0,1,10.374,3.836,4.181,4.181,0,1,1,.9,8.262H138.711a5.644,5.644,0,0,1,0-11.289" transform="translate(-21.955 -13.615)" fill="#fff" />
                    <path id="Path_2010" data-name="Path 2010" d="M141.857,0c23.18,0,39.55,12.7,39.55,47.145S164.645,94.42,141.857,94.42c-27.5,0-39.549-19.906-39.549-47.276C102.308,12.964,118.94,0,141.857,0m-12.7,53.953c0,27.633,0,37.717,12.7,37.717,12.441,0,12.834-9.953,12.834-36.667V43.477c0-30.906,0-40.728-12.834-40.728-12.572,0-12.7,11-12.7,39.549Z" transform="translate(-16.88 0)" fill="#ef5350" />
                    <path id="Path_2011" data-name="Path 2011" d="M277.916,47.274a4.566,4.566,0,0,0-1.622.3,7.411,7.411,0,0,0-9.2-5.713,12.174,12.174,0,0,0-22.6-3.479,8.778,8.778,0,0,0-12.38,5.826,6.151,6.151,0,1,0-1.021,12.217h46.823a4.576,4.576,0,1,0,0-9.152" transform="translate(-37.113 -5.293)" fill="#fff" />
                    <path id="Path_2012" data-name="Path 2012" d="M225.7,90.907h12.311V69.955h-35.49V67.466l36.93-65.74h22.656V66.811h12.965V55.418h2.488V79.645h-2.488v-9.69H262.105V90.907h9.036V93.4H225.7Zm12.311-24.1V11.678l-2.654,4.675L206.71,66.811Z" transform="translate(-33.413 -0.285)" fill="#ef5350" />
                    <path id="Path_2013" data-name="Path 2013" d="M33.593,40.821a5.064,5.064,0,0,0-1.649.279c0-.093.007-.186.007-.279a10.176,10.176,0,0,0-19.472-4.138A7.892,7.892,0,1,0,7.893,51h25.7a5.088,5.088,0,0,0,0-10.176" transform="translate(0 -5.056)" fill="#fff" />
                    <path id="Path_2014" data-name="Path 2014" d="M290.083,93.29a3.576,3.576,0,0,0-1.163.2c0-.065.005-.13.005-.2a7.179,7.179,0,0,0-13.738-2.919,5.568,5.568,0,1,0-3.235,10.1h18.131a3.59,3.59,0,1,0,0-7.179" transform="translate(-43.951 -14.207)" fill="#f8f8f8" />
                    <path id="Path_2015" data-name="Path 2015" d="M226.027,12.509a2.863,2.863,0,0,1,.931.157c0-.052,0-.1,0-.157a5.743,5.743,0,0,1,10.99-2.336,4.455,4.455,0,1,1,2.589,8.08H226.027a2.872,2.872,0,0,1,0-5.744" transform="translate(-36.818 -1.116)" fill="#f8f8f8" />
                    <g id="Group_17692" data-name="Group 17692" transform="translate(85.522 27.159)" opacity="0.29" style={{ mixBlendMode: "multiply", isolation: "isolate" }}>
                        <g id="Group_17691" data-name="Group 17691">
                            <g id="Group_17690" data-name="Group 17690" clipPath="url(#clip-path-3)">
                                <path id="Path_2016" data-name="Path 2016" d="M189.3,32.526,164.942,40.12c.013,2.7.014,5.6.014,8.725V60.37c0,8.146-.039,14.724-.442,19.946l15.854,8.823a32.977,32.977,0,0,0,3.241-4.061l.819-2.174,6.133-16.288a83.86,83.86,0,0,0,1.11-14.1A76.083,76.083,0,0,0,189.3,32.526" transform="translate(-112.665 -32.525)" fill="#212121" />
                                <path id="Path_2017" data-name="Path 2017" d="M129.173,62.392V51.147L107.6,57.871l-5.184,1.616c.02.457.038.916.066,1.369l26.707,10.3c-.019-2.7-.02-5.612-.02-8.761" transform="translate(-102.42 -35.598)" fill="#212121" />
                            </g>
                        </g>
                    </g>
                    <path id="Path_2018" data-name="Path 2018" d="M128.275,56.525,130.94,80.07l6.329-13.987,17.424-8.536,37.1-39.6Z" transform="translate(-20.855 -2.961)" fill="#fafafa" />
                    <path id="Path_2019" data-name="Path 2019" d="M91.495,47.416l31.361,12.09,63.176-41.559Z" transform="translate(-15.096 -2.961)" fill="#fff" />
                    <path id="Path_2020" data-name="Path 2020" d="M140.725,63.666l29.938,16.658,23.491-62.379L165.729,42.27Z" transform="translate(-23.218 -2.961)" fill="#fff" />
                    <path id="Path_2021" data-name="Path 2021" d="M102.426,49.157,91.494,52.565l31.361,12.091,7.306-4.805Z" transform="translate(-15.096 -8.11)" fill="#eee" />
                    <path id="Path_2022" data-name="Path 2022" d="M146.738,66.537l-6.014,5.146,29.938,16.658,2.644-7.021Z" transform="translate(-23.218 -10.978)" fill="#eee" />
                    <path id="Path_2023" data-name="Path 2023" d="M136.358,61.963,138,80.667l-6.628,6.665-2.324-20.564Z" transform="translate(-21.292 -10.223)" fill="#e0e0e0" />
                    <path id="Path_2024" data-name="Path 2024" d="M139.258,72.7l-7.422,16.4,13.146-13.22Z" transform="translate(-21.751 -11.995)" fill="#f5f5f5" />
                    <path id="Path_2025" data-name="Path 2025" d="M107.31,53.163c0,.784-1.551,1.42-3.466,1.42s-3.466-.636-3.466-1.42,1.552-1.42,3.466-1.42,3.466.636,3.466,1.42" transform="translate(-16.561 -8.537)" fill="#ef5350" />
                    <path id="Path_2026" data-name="Path 2026" d="M33.593,40.821a5.064,5.064,0,0,0-1.649.279c0-.093.007-.186.007-.279a10.176,10.176,0,0,0-19.472-4.138A7.892,7.892,0,1,0,7.893,51h25.7a5.088,5.088,0,0,0,0-10.176" transform="translate(0 -5.056)" fill="#f8f8f8" />
                    <path id="Path_2028" data-name="Path 2028" d="M226.027,12.509a2.863,2.863,0,0,1,.931.157c0-.052,0-.1,0-.157a5.743,5.743,0,0,1,10.99-2.336,4.455,4.455,0,1,1,2.589,8.08H226.027a2.872,2.872,0,0,1,0-5.744" transform="translate(-37.694 -2.43)" fill="#fff" />
                </g>
            </g>
        </g>
    </g>
</svg>
