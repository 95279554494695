import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useTranslation } from "react-i18next";


export const AppEmailVerificationDialog = (props) => {
    const { t } = useTranslation();
    const { onClick, onClose, open } = props;
    const handleClose = () => {
        onClose();
    };

    const handleVerifying = () => {
        onClick();
    };
    return (
        <Dialog open={open}>
            <DialogTitle style={{ backgroundColor:'#03826F', textTransform:'uppercase', color:'#fff', textAlign: "center" }}>{t("account_verification")}</DialogTitle>
            <DialogContent>{t("account_verification_body")}</DialogContent>
            <DialogActions>
                <Button style={{  textTransform:'uppercase', textAlign: "center" }} onClick={handleClose} >Close</Button>
                <Button style={{ backgroundColor:'#03826F', textTransform:'uppercase', color:'#fff', textAlign: "center" }} onClick={handleVerifying} >Verfiy</Button>
            </DialogActions>

        </Dialog>
    )
}