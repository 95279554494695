import { Box, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { DividerSVG } from "../assets/dividerSVG";
import { Page404SVG } from "../assets/404SVG";
import { useTranslation } from "react-i18next";
import { AppPaper } from "./Paper";
import { AppContainer } from "./Container";
export const AppNotFound404 = () => {
  const { t } = useTranslation();
  return (
    <AppContainer>
      <AppPaper>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box paddingTop="32px" color="#EF5350" textAlign="center">
            <Typography variant="h4">{t("404_title")}</Typography>
          </Box>
          <Box>
            <DividerSVG color="#EF5350" />
          </Box>
          <Box m="64px">
            <Page404SVG />
          </Box>
          <Box
            marginBottom="64px"
            color="#484848"
            textAlign="center"
            maxWidth="400px"
          >
            <Typography variant="body2">{t("404_message")}</Typography>
          </Box>
          <Box marginBottom="64px">
            <Link style={{ textDecoration: "none" }} to="/">
              <Button disableElevation color="primary" variant="contained">
                {t("go_home")}
              </Button>
            </Link>
          </Box>
        </Box>
      </AppPaper>
    </AppContainer>
  );
};
