import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
export const AppWrapper = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root} dir={t("dir")}>
      {children}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    minWidth: "375px",
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "165px 178px auto 200px",
  },
}));
