import { Suspense } from "react";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
  Box,
} from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AppSubscribe } from "./components/Subscribe";
import "./App.css";
import { AppHeader } from "./components/Header";
import { AppFooter } from "./components/Footer";
import { AppPlans } from "./components/Plans";
import { AppPlanDetails } from "./components/PlanDetails";
import { AppWrapper } from "./components/Wrapper";
import { AppPaymentSuccess } from "./components/PaymentSuccess";
import { AppPaymentFailure } from "./components/PaymentFailure";
import { AppNotFound404 } from "./components/NotFound404";
import { useFBScript } from "./js/externalScripts";



const App = () => {
  useFBScript();
  const classes = useStyles();
  return (
    <Suspense fallback="Loading..">
      <ThemeProvider theme={customTheme}>
        <BrowserRouter>
          <AppWrapper>
            <Box className={classes.header}>
              <AppHeader />
            </Box>
            <Switch>
              <Route
                exact
                path={[
                  "/",
                  "/subscribe",
                  "/subscribe/:planName",
                  "/subscribe/:planName/:promoCode",
                  "/subscribe/:planName/:clientId/:branchId",
                  "/subscribe/:planName/:clientId/:branchId/:promoCode",
                ]}
              >
                <Box className={classes.main}>
                  <AppSubscribe />
                </Box>
              </Route>
              <Route
                exact
                path={["/plans/:planName?/:clientId?/:branchId?", "/plans"]}
              >
                <Box className={classes.main}>
                  <AppPlans />
                </Box>
              </Route>
              <Route
                exact
                path={[
                  "/details/:planName",
                  "/details/:planName/:clientId?/:branchId?",
                ]}
              >
                <Box className={classes.main}>
                  <AppPlanDetails />
                </Box>
              </Route>
              <Route
                exact
                path={["/success/:cartId"]}
              >
                <Box className={classes.main}>
                  <AppPaymentSuccess />
                </Box>
              </Route>
              <Route
                exact
                path={["/failure/:cartId"]}
              >
                <Box className={classes.main}>
                  <AppPaymentFailure />
                </Box>
              </Route>
              <Route>
                <Box className={classes.main}><AppNotFound404 /></Box>
              </Route>
            </Switch>
            <AppFooter />
          </AppWrapper>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: "0",
    gridColumn: "1",
    gridRow: "1 / span 2",
  },
  main: {
    zIndex: "1",
    gridColumn: "1",
    gridRow: "2 / span 3",
  },
}));

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2A9D8F",
    },
    secondary: {
      main: "#12786B",
    },
  },
});

export default App;
