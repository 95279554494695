import {
  makeStyles,
  Toolbar,
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppContainer } from "./Container";
import LazyWaitSVG from "../assets/icons/lazy_wait.svg";
import Lock from "../assets/icons/lock.svg";
import { useTranslation } from "react-i18next";

export const AppHeader = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const btnVariant = matches ? "outlined" : "text";

  const changeLanguageTo = (lang) => {
    if (lang === "ar") {
      i18n.changeLanguage("ar");
      document.body.dir = "rtl";
    } else {
      i18n.changeLanguage("en");
      document.body.dir = "ltr";
    }
  };

  const onBtnClick = () => {
    t("lang") === "en" ? changeLanguageTo("ar") : changeLanguageTo("en");
  };

  return (
    <Toolbar component="header" className={classes.root}>
      <AppContainer>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <img alt="Lazy Wait logo" src={LazyWaitSVG} />
          </Box>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item className={classes.secureCheckout}>
              <img alt="Lock icon" src={Lock} />
            </Grid>
            <Grid item className={classes.secureCheckout}>
              <Typography variant="body1">{t("checkout")}</Typography>
            </Grid>
            <Grid item>
              <Button onClick={onBtnClick} variant={btnVariant} color="inherit">
                {t("lang") === "en" ? "العربية" : "English"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AppContainer>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  logo: {},
  root: {
    [theme.breakpoints.up("md")]: {
      minHeight: "356px",
      paddingTop: "75px",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "300px",
      paddingTop: "60px",
    },
    color: "#FFFFFF",
    backgroundColor: "#03826F",
    alignItems: "flex-start",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {},
  secureCheckout: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
