export const Price = ({ monthly, yearly, save, years, days }) => {
	if (yearly) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="220" height="110" viewBox="0 0 220 110">
				<g id="Group_17726" data-name="Group 17726" transform="translate(-256 -750)">
					{/* <g id="Group_17714" data-name="Group 17714" transform="translate(256 750)">
						<rect id="Rectangle_698" data-name="Rectangle 698" width="95" height="95" rx="7" transform="translate(0)" fill="#f9f9f9" />
						<g
							id="Rectangle_699"
							data-name="Rectangle 699"
							transform="translate(6 6)"
							fill="none"
							stroke="#161616"
							strokeWidth="1"
							strokeDasharray="2"
						>
							<rect width="84" height="83" rx="3" stroke="none" />
							<rect x="0.5" y="0.5" width="83" height="82" rx="2.5" fill="none" />
						</g>
						<text
							id="_1953"
							data-name="1953"
							transform="translate(23.735 53.905)"
							fill="#373b41"
							stroke="#373b41"
							strokeWidth="1"
							fontSize="18"
							fontFamily="Helvetica"
						>
							<tspan x="0" y="0">
								{monthly}
							</tspan>
						</text>
						<text id="SR" transform="translate(65.543 39.157)" fill="#373b41" fontSize="11" fontFamily="Helvetica">
							<tspan x="0" y="0">
								SR
							</tspan>
						</text>
						<text
							id="MONTHLY"
							transform="translate(13 22)"
							fill="#686868"
							fontSize="9"
							fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif"
							opacity="0.9"
						>
							<tspan x="0" y="0">
								MONTHLY
							</tspan>
						</text>
					</g> */}

					<g id="Group_17716" data-name="Group 17716" transform="translate(365.06 750)">
						<rect id="Rectangle_698-2" data-name="Rectangle 698" width="111" height="110" rx="7" transform="translate(-0.06 0)" fill="#f9f9f9" />
						<g
							id="Rectangle_699-2"
							data-name="Rectangle 699"
							transform="translate(4.94 5)"
							fill="none"
							stroke="#161616"
							strokeWidth="1"
							strokeDasharray="2"
						>
							<rect width="101" height="100" rx="3" stroke="none" />
							<rect x="0.5" y="0.5" width="100" height="99" rx="2.5" fill="none" />
						</g>
						<text
							id="_2679"
							data-name="2679"
							transform="translate(20.787 61.675)"
							fill="#373b41"
							stroke="#373b41"
							strokeWidth="1"
							fontSize="26"
							fontFamily="Helvetica"
						>
							<tspan x="0" y="0">
								{yearly}
							</tspan>
						</text>
						<text id="SR-2" data-name="SR" transform="translate(77.532 43.58)" fill="#373b41" fontSize="12" fontFamily="Helvetica">
							<tspan x="0" y="0">
								{yearly.toLowerCase() === 'free' ? '' : 'SR'}
							</tspan>
						</text>

						<text
							id="YEARLY"
							transform="translate(15.94 22)"
							fill="#5a5a5a"
							fontSize="10"
							fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif"
						>
							<tspan x="0" y="0">
								{yearly.toLowerCase() === 'free' ? '' : 'YEARLY'}
							</tspan>
						</text>
					</g>
					{/* <g id="Group_17725" data-name="Group 17725" transform="translate(-7 -50)">
						<path
							id="Path_2036"
							data-name="Path 2036"
							d="M7165,7959v21.573h82.321l-12.967-10.25L7247.321,7959Z"
							transform="translate(-6793.062 -7086)"
							fill="#cf0000"
						/>
						<text
							id="_20_SAVE"
							data-name="20% SAVE"
							transform="translate(379 888)"
							fill="#fff"
							fontSize="11"
							fontWeight="bold"
							fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif"
						>
							<tspan x="0" y="0">
								{save} SAVE
							</tspan>
						</text>
					</g> */}
					{years || days ? (
						<g id="Group_17725" data-name="Group 17725" transform="translate(-7 -50)">
							<path
								id="Path_2036"
								data-name="Path 2036"
								d="M7165,7959v21.573h82.321l-12.967-10.25L7247.321,7959Z"
								transform="translate(-6793.062 -7086)"
								fill="#cf0000"
							/>
							<text
								id="_20_SAVE"
								data-name="20% SAVE"
								transform="translate(379 888)"
								fill="#fff"
								fontSize="11"
								fontWeight="bold"
								fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif"
							>
								<tspan x="0" y="0">
									{years ? `${years} Years` : `${days} Days`}
								</tspan>
							</text>
						</g>
					) : (
						''
					)}
				</g>
			</svg>
		);
	} else {
		// return (<svg xmlns="http://www.w3.org/2000/svg" width="220" height="110" viewBox="0 0 220 110">
		//     <g id="Group_17726" data-name="Group 17726" transform="translate(-256 -750)">
		//         <g id="Group_17714" data-name="Group 17714" transform="translate(256 750)">
		//             <rect id="Rectangle_698" data-name="Rectangle 698" width="95" height="95" rx="7" transform="translate(0)" fill="#f9f9f9" />
		//             <g id="Rectangle_699" data-name="Rectangle 699" transform="translate(6 6)" fill="none" stroke="#161616" strokeWidth="1" strokeDasharray="2">
		//                 <rect width="84" height="83" rx="3" stroke="none" />
		//                 <rect x="0.5" y="0.5" width="83" height="82" rx="2.5" fill="none" />
		//             </g>
		//             <text id="_1953" data-name="1953" transform="translate(23.735 53.905)" fill="#373b41" stroke="#373b41" strokeWidth="1" fontSize="18" fontFamily="Helvetica"><tspan x="0" y="0">0</tspan></text>
		//             <text id="SR" transform="translate(65.543 39.157)" fill="#373b41" fontSize="11" fontFamily="Helvetica"><tspan x="0" y="0">SR</tspan></text>
		//             <text id="MONTHLY" transform="translate(13 22)" fill="#686868" fontSize="9" fontFamily="system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif" opacity="0.9"><tspan x="0" y="0">14 DAYS</tspan></text>
		//         </g>
		//     </g>
		// </svg>)
		return <div />;
	}
};
