import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createPaymentPage,
  getPaymentToken,
  sendMailToSupport,
  createClient,
  createBranch,
  createUser,
  createLicense,
  getPlan,
  hadTrial,
  createAccount,
  VerifyAccount,
  getDanatAlkhaleejContract,
  getMarketplaceContract,
  getBranch,
  updateBranch,
} from "../api/API";
import {
  validateCardInfo,
  validateCustomerInfo,
  validateBrandInfo,
} from "../js/validators";
import { AppContainer } from "./Container";
import { CardDetailsForm } from "./FormCardDetails";
import { AppCustomerDetailsForm } from "./FormCustomerDetails";
import { AppBrandDetailsForm } from "./FormBrandDetails";
import { AppOrderSummary } from "./OrderSummary";
import { AppPaper } from "./Paper";
import { AppPayment } from "./Payment";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../api/Constants";
import { AppGuestDialog } from "./GuestDialog";
import { AppHadTrialDialog } from "./HadTrialDialog";
import { AppCreatingTrialDialog } from "./CreatingTrialDialog";
import { AppTrialCreated } from "./TrialCreated";
import { AppEmailVerificationDialog } from "./EmailVerificationDialog";
import { getClientContract } from "../api/API";

export const AppSubscribe = () => {
  const { t } = useTranslation();
  const [customerFormErrors, setCustomerFormErrors] = useState({
    first_name: false,
    last_name: false,
    owner_email: false,
    country: false,
    state: false,
    city: false,
    street1: false,
    callingCode: false,
    phone: false,
    zipCode: false,
    subscriptionType: false,
    // promoCode: false,
    ip: false,
    vatNumber: false,
  });
  const [brandFormErrors, setBrandFormErrors] = useState({
    brand_name: false,
    branch_name: false,
    category: false,
    sub_category: false,
    callingCode: false,
    phone: false,
  });
  const [cardFormErrors, setCardFormErrors] = useState({
    number: false,
    month: false,
    year: false,
    cvv: false,
  });
  let { planName, branchId, clientId, promoCode } = useParams();
  function getSteps(t) {
    if (planName == "free" || plan_name == "free") {
      return [t("customer_details"), t("brand_details"), t("trial_created")];
    } else if (planName == "danatalkhaleej" || plan_name == "danatalkhaleej") {
      return [
        t("customer_details"),
        t("brand_details"),
        t("terms"),
        t("lwAppTerms"),
        t("payment_invoice"),
      ];
    } else {
      return [
        t("customer_details"),
        t("brand_details"),
        t("terms"),
        // t("payment_method"),
        // t("card_details"),
        t("payment_invoice"),
      ];
    }
  }
  const classes = useStyles();
  const param = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [plan_name, setPlanName] = useState(
    param.planName ? param.planName : "plus"
  );
  const [promo_code, setPromoCode] = useState(param.promoCode);
  const [branch_id, setBranchId] = useState(param.branchId);
  const [client_id, setClientId] = useState(param.clientId);
  const [branch, setBranch] = useState({});
  const [client, setClient] = useState({});
  const steps = getSteps(t);
  const [customerFormObj, setCustomerFormObj] = useState(customerObject);
  const [brandFormObj, setBrandFormObj] = useState(brandObject);
  const [selectedPlanPrice, setSelectedPlanPrice] = useState(0);
  const [selectedPlanDiscount, setSelectedPlanDiscount] = useState(0);
  const [selectedPromoId, setSelectedPromoId] = useState("");
  const [cardFormObj, setCardFormObj] = useState(cardObject);
  const [payTabsError, setPayTabsError] = useState();
  const [open, setOpen] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [agree, setAgree] = useState(false);
  const [lwAppAgree, setLwAppAgree] = useState(false);
  const [user, setUser] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("other");
  const [loading, setLoading] = useState(false);
  const [contract, setContact] = useState({});
  const [marketplaceContract, setMarketplaceContract] = useState({});

  const handleCheckboxChange = (value) => {
    setAgree(value);
    console.log(value);
  };

  const handleClose = (value) => {
    setOpen(false);
    setVerifying(false);
    setLoading(false);
  };

  const handleVerifying = async () => {
    let temp_user = await VerifyAccount(user.email);
    setVerifying(!temp_user.emailVerified);
    setLoading(!temp_user.emailVerified);
    setUser(temp_user);
    if (temp_user.emailVerified) {
      handleNext();
    }
  };

  useEffect(async () => {
    if (planName == "danatalkhaleej" || plan_name == "danatalkhaleej") {
      const contracts = await Promise.all([
        getDanatAlkhaleejContract(),
        getMarketplaceContract(),
      ]);
      setContact(contracts[0]);
      setMarketplaceContract(contracts[1]);
    } else {
      setContact(await getClientContract());
    }
  }, []);

  const handleBack = () => {
    // if passed branchId && clientId as parameters skip brand step
    if (branchId && clientId && activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const handleNext = async () => {
    try {
      setLoading(true);
      if (
        activeStep === 0 &&
        validateCustomerInfo(
          customerFormObj,
          setCustomerFormErrors,
          clientId ? true : false
        )
      ) {
        console.log("here 1");
        if (plan_name == "free") {
          const had_trial = await hadTrial(
            customerFormObj.owner_email,
            clientId || ""
          );
          if (had_trial == true) {
            console.log("had a demo");
            setOpen(true);
            setLoading(false);
            return;
          }
        }

        if (clientId && branchId && branchId != "new") {
          setActiveStep((prevActiveStep) => prevActiveStep + 2);
          setLoading(false);
          return;
        }
        console.log("here 2");
        let temp_user = null;
        if (!clientId) {
          temp_user = await createAccount({
            email: customerFormObj.owner_email,
            password: Buffer.from(customerFormObj.password).toString("base64"),
            displayName:
              customerFormObj.first_name + " " + customerFormObj.last_name,
          });
        }

        console.log("here 3");
        if (temp_user != "NOT_FAILD_PASSWORD") {
          setUser(temp_user);
          if (temp_user && !temp_user.emailVerified) {
            setVerifying(true);
            return;
          } else {
            // if passed branchId && clientId as parameters skip brand step
            if (branchId && clientId && branchId != "new") {
              setActiveStep((prevActiveStep) => prevActiveStep + 2);
            } else {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          }
        } else {
          setLoading(false);
        }
      }
      if (
        activeStep === 1 &&
        validateBrandInfo(brandFormObj, setBrandFormErrors)
      ) {
        if (!branchId && !clientId && plan_name == "free") {
          await createClientBranch();
          setActiveStep(6);
        } else {
          if ((!branchId && !clientId) || branchId == "new") {
            await createClientBranch();
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      if (
        activeStep === 2 &&
        (planName == "danatalkhaleej" || plan_name == "danatalkhaleej")
      ) {
        setAgree(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (activeStep === 2) {
        setAgree(false);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //TODO
        setPaymentMethod("other");
        handleOther();
      }

      if (
        activeStep === 3 &&
        (planName == "danatalkhaleej" || plan_name == "danatalkhaleej")
      ) {
        setLwAppAgree(false);
        setPaymentMethod("other");
        handleOther();
      } else if (activeStep === 3) {
        if (paymentMethod === "mada")
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        else {
          console.log("other payment method");
          handleOther();
        }
      }
      if (
        activeStep === 4 &&
        (planName == "danatalkhaleej" || plan_name == "danatalkhaleej")
      ) {
        if (paymentMethod === "mada")
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        else {
          console.log("other payment method");
          handleOther();
        }
      } else if (
        activeStep === 4 &&
        validateCardInfo(cardFormObj, setCardFormErrors) &&
        branch_id &&
        client_id
      ) {
        await handlePlaceOrder();
      }
      if (
        activeStep === 5 &&
        validateCardInfo(cardFormObj, setCardFormErrors) &&
        branch_id &&
        client_id
      ) {
        await handlePlaceOrder();
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  const createClientBranch = async () => {
    try {
      setOpen(true);
      let request = {
        ...customerFormObj,
        ...brandFormObj,
        licence: {},
        language: t("lang"),
      };
      if (plan_name && plan_name.toLowerCase() == "free") {
        request["had_trial"] = true;
      }
      let client = null;
      if (!clientId) {
        client = await createClient(request);
        request["client_id"] = client.client_id;
        request["client_name"] = client.name_lan_p;
      } else {
        request["client_id"] = clientId;
        request["client_name"] = brandFormObj.brand_name;
      }

      let branch = null;
      if (!branch_id || branch_id == "new") {
        branch = await createBranch(request);
        setBranchId(branch.branch_id);
      } else {
        request["branch_id"] = branch_id;
        branch = await updateBranch(request);
      }
      request["branch_id"] = branch.branch_id;
      request["branch_name"] = branch.name_lan_p;
      await createUser(request);
      if (plan_name && plan_name.toLowerCase() == "free") {
        const plan = await getPlan("free");
        request.licence["allowed_admins"] = plan.allowed_admins;
        request.licence["allowed_terminals"] = plan.allowed_terminals;
        request.licence["on_trial"] = true;
        request.licence["payment_complete"] = true;
        request.licence["status"] = "completed";
        request.licence["plan_info"] = {
          plan_options: plan.plan_options,
          discounts: "0%",
          payment_amount: "0",
          payment_complete: false,
          plan_name: plan_name,
          plan_price: "0",
          promotion: "",
          savings: "0",
          subscription_type: "14DAYS",
        };

        await createLicense(request);
      }
      setClient(client);
      setBranch(branch);
      setClientId(client?.client_id || clientId);
      setBranchId(branch.branch_id);
    } catch (e) {
      throw new Error(e.message);
    } finally {
      setOpen(false);
    }
  };
  const handleOther = async () => {
    try {
      console.log("here 1");
      if (!plan_name) {
        setPlanName("plus");
      }
      console.log("here 2");
      const paymentPageRequest = {
        paymentMethod: paymentMethod,
        customer: customerFormObj,
        cart: {
          projectId: "DirectPayment",
          env: process.env.NODE_ENV,
          isPaymentComplete: false,
          subscriptionType: customerFormObj.subscriptionType,
          description: `The ${plan_name} plan.`,
          currency: "SAR",
          //contract: contract,
          items: [
            {
              itemId: SUBSCRIPTION_PLANS_IDS[plan_name.toLowerCase()],
              name: plan_name,
              price: selectedPlanPrice,
            },
          ],
          amount: selectedPlanPrice,
          discount: selectedPlanDiscount,
          promoId: selectedPromoId,
          vat: 0.15,
          clientId: client_id,
          branchId: branch_id,
          first_name: customerFormObj.first_name,
          last_name: customerFormObj.last_name,
          email: customerFormObj.owner_email,
          callingCode: customerFormObj.callingCode,
          phone: `0${customerFormObj.phone}`,
          language: t("lang"),
          vatNumber: customerObject.vatNumber,
        },
        returnURL: CONSTANTS.RETURN_URL,
      };

      console.log("here 2");
      console.log("paymentPageRequest", paymentPageRequest);
      await createPaymentPage(paymentPageRequest);
    } catch (e) {
      alert("error with creating payment page");
    }
  };

  const handlePlaceOrder = async () => {
    try {
      const paytabsTokenRequest = {
        currency: "SAR",
        payment: {
          method: "card",
          cardNumber: cardFormObj.number,
          expiryMonth: cardFormObj.month,
          expiryYear: cardFormObj.year,
          cvv: cardFormObj.cvv,
        },
      };
      const paymentTokenResponse = await getPaymentToken(paytabsTokenRequest);
      if (paymentTokenResponse.error) {
        sendMailToSupport({
          ...customerFormObj,
          errorMessage: paymentTokenResponse.errorText,
          clientId: client_id,
          branchId: branch_id,
        });
        setPayTabsError(paymentTokenResponse);
        if (paymentTokenResponse.errorField === "number") {
          setCardFormErrors({
            number: true,
            month: false,
            year: false,
            cvv: false,
          });
        }

        console.log(paymentTokenResponse);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setTimeout(async () => {
          const paymentPageRequest = {
            paymentMethod: paymentMethod,
            customer: customerFormObj,
            cart: {
              projectId: "DirectPayment",
              env: process.env.NODE_ENV,
              isPaymentComplete: false,
              subscriptionType: customerFormObj.subscriptionType,
              description: `The ${plan_name} plan.`,
              currency: "SAR",
              items: [
                {
                  itemId: SUBSCRIPTION_PLANS_IDS[plan_name.toLowerCase()],
                  name: plan_name,
                  price: selectedPlanPrice,
                },
              ],
              amount: selectedPlanPrice,
              discount: selectedPlanDiscount,
              promoId: selectedPromoId,
              vat: 0.15,
              clientId: client_id,
              branchId: branch_id,
              first_name: customerFormObj.first_name,
              last_name: customerFormObj.last_name,
              email: customerFormObj.owner_email,
              callingCode: customerFormObj.callingCode,
              phone: `0${customerFormObj.phone}`,
              language: t("lang"),
              vatNumber: customerObject.vatNumber,
            },
            paymentToken: paymentTokenResponse,
            returnURL: CONSTANTS.RETURN_URL,
          };
          console.log(paymentPageRequest);
          await createPaymentPage(paymentPageRequest);
        }, 3000);
      }
    } catch (e) {
      alert("error with creating payment page");
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AppPaper>
            <Box className={classes.containerBox}>
              <Box className={classes.customerDetails}>
                <AppCustomerDetailsForm
                  formErrors={customerFormErrors}
                  setPrice={setSelectedPlanPrice}
                  formObj={customerFormObj}
                  setFormObj={setCustomerFormObj}
                />
              </Box>
              <Box className={classes.orderSummary}>
                <AppOrderSummary
                  promoCode={promo_code}
                  setPromoCode={setPromoCode}
                  promoCodeId={selectedPromoId}
                  setPromoCodeId={setSelectedPromoId}
                  discount={selectedPlanDiscount}
                  setDiscount={setSelectedPlanDiscount}
                  price={selectedPlanPrice}
                  setPrice={setSelectedPlanPrice}
                  formObj={customerFormObj}
                  setFormObj={setCustomerFormObj}
                  plan_id={SUBSCRIPTION_PLANS_IDS[plan_name.toLowerCase()]}
                />
              </Box>
              <Box>
                <AppEmailVerificationDialog
                  open={verifying}
                  onClick={handleVerifying}
                  onClose={handleClose}
                />
                <AppHadTrialDialog open={open} onClose={handleClose} />
              </Box>
            </Box>
          </AppPaper>
        );
      case 1:
        return (
          <AppPaper>
            <Box className={classes.containerBox}>
              <Box className={classes.customerDetails}>
                <AppBrandDetailsForm
                  formErrors={brandFormErrors}
                  setPrice={setSelectedPlanPrice}
                  formObj={brandFormObj}
                  setFormObj={setBrandFormObj}
                />
              </Box>
              <Box className={classes.orderSummary}>
                <AppOrderSummary
                  promoCode={promo_code}
                  setPromoCode={setPromoCode}
                  promoCodeId={selectedPromoId}
                  setPromoCodeId={setSelectedPromoId}
                  discount={selectedPlanDiscount}
                  setDiscount={setSelectedPlanDiscount}
                  price={selectedPlanPrice}
                  setPrice={setSelectedPlanPrice}
                  formObj={customerFormObj}
                  setFormObj={setCustomerFormObj}
                  plan_id={SUBSCRIPTION_PLANS_IDS[plan_name.toLowerCase()]}
                />
              </Box>
              <Box>
                <AppCreatingTrialDialog open={open} onClose={handleClose} />
              </Box>
            </Box>
          </AppPaper>
        );
      case 2:
        return (
          <AppPaper>
            <Box className={classes.containerBox}>
              <Box
                className={classes.customerDetails}
                style={{ overflow: "hidden" }}
              >
                <FormControl component="fieldset">
                  <FormLabel
                    style={{ padding: "0.5rem", fontWeight: "bold" }}
                    component="legend"
                  >
                    {t("terms")}
                  </FormLabel>
                </FormControl>
              </Box>
              <Box className={classes.orderSummary}>
                <Checkbox
                  checked={agree}
                  label={t("agree")}
                  onChange={(e) => handleCheckboxChange(e.target.checked)}
                />{" "}
                {t("agree")}
              </Box>
            </Box>
            <Box>
              <Box style={{ overflow: "hidden" }}>
                <div
                  style={{ overflow: "auto" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      contract && contract.contract && contract.contract.ar
                        ? contract.contract.ar
                        : "",
                  }}
                />
              </Box>
            </Box>
          </AppPaper>
        );
      case 3:
        return (
          <AppPaper>
            <Box className={classes.containerBox}>
              <Box
                className={classes.customerDetails}
                style={{ overflow: "hidden" }}
              >
                <FormControl component="fieldset">
                  <FormLabel
                    style={{ padding: "0.5rem", fontWeight: "bold" }}
                    component="legend"
                  >
                    {t("lwAppTerms")}
                  </FormLabel>
                </FormControl>
              </Box>
              <Box className={classes.orderSummary}>
                <Checkbox
                  checked={lwAppAgree}
                  label={t("agree")}
                  onChange={(e) => setLwAppAgree(e.target.checked)}
                />{" "}
                {t("agree")}
              </Box>
            </Box>
            <Box>
              <Box style={{ overflow: "hidden" }}>
                <div
                  style={{ overflow: "auto" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      marketplaceContract &&
                        marketplaceContract.contract &&
                        marketplaceContract.contract.ar
                        ? marketplaceContract.contract.ar
                        : "",
                  }}
                />
              </Box>
            </Box>
          </AppPaper>
        );
      // case 3:
      //   return (
      //     <AppPaper>
      //       <Box className={classes.containerBox}>
      //         <Box p="24px" className={classes.customerDetails}>
      //           <FormControl component="fieldset">
      //             <FormLabel component="legend">{t("payment_method")}</FormLabel>
      //             <RadioGroup
      //               aria-label="payment method"
      //               name="payment_method"
      //               value={paymentMethod}
      //               onChange={(e) => setPaymentMethod(e.target.value)}
      //             >
      //               <FormControlLabel
      //                 value="mada"
      //                 control={<Radio />}
      //                 label="Mada"
      //               />
      //               <FormControlLabel
      //                 value="other"
      //                 control={<Radio />}
      //                 label="Other (Visa, MasterCard)"
      //               />
      //             </RadioGroup>
      //           </FormControl>
      //         </Box>
      //         <Box className={classes.orderSummary}>
      //           <AppOrderSummary
      //             promoCode={promo_code}
      //             setPromoCode={setPromoCode}
      //             promoCodeId={selectedPromoId}
      //             setPromoCodeId={setSelectedPromoId}
      //             discount={selectedPlanDiscount}
      //             setDiscount={setSelectedPlanDiscount}
      //             price={selectedPlanPrice}
      //             setPrice={setSelectedPlanPrice}
      //             formObj={customerFormObj}
      //             setFormObj={setCustomerFormObj}
      //             plan_id={SUBSCRIPTION_PLANS_IDS[plan_name.toLowerCase()]}
      //           />
      //         </Box>
      //       </Box>
      //     </AppPaper>
      //   );
      // case 4:
      //   return (
      //     <AppPaper>
      //       <Box className={classes.containerBox}>
      //         <Box className={classes.customerDetails}>
      //           <CardDetailsForm
      //             formErrors={cardFormErrors}
      //             formObj={cardFormObj}
      //             setFormObj={setCardFormObj}
      //             payTabsError={payTabsError}
      //           />
      //         </Box>
      //         <Box className={classes.orderSummary}>
      //           <AppOrderSummary
      //             promoCode={promo_code}
      //             setPromoCode={setPromoCode}
      //             promoCodeId={selectedPromoId}
      //             setPromoCodeId={setSelectedPromoId}
      //             discount={selectedPlanDiscount}
      //             setDiscount={setSelectedPlanDiscount}
      //             price={selectedPlanPrice}
      //             setPrice={setSelectedPlanPrice}
      //             formObj={customerFormObj}
      //             setFormObj={setCustomerFormObj}
      //             plan_id={SUBSCRIPTION_PLANS_IDS[plan_name.toLowerCase()]}
      //           />
      //         </Box>
      //       </Box>
      //     </AppPaper>
      //   );
      case 5:
        return (
          <AppPaper>
            <AppPayment />
          </AppPaper>
        );
      case 6:
        return (
          <AppPaper>
            <AppTrialCreated />
          </AppPaper>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <AppContainer>
      <main className={classes.rootStepper}>
        <Stepper
          style={{
            borderRadius: ".25rem",
            overflow: "hidden",
            boxShadow:
              "0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)",
            transition: "height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }}
          activeStep={activeStep}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (clientId && index !== 1) {
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            } else {
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            }
          })}
        </Stepper>
        <Box>
          <Box>
            {getStepContent(activeStep)}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                disabled={activeStep === 0 || activeStep === 5}
                onClick={handleBack}
                className={classes.button}
              >
                {t("back")}
              </Button>
              <Button
                disabled={
                  (agree === false && activeStep === 2) ||
                  (lwAppAgree === false && activeStep === 3) ||
                  activeStep === 4 ||
                  activeStep === 5 ||
                  loading == true
                }
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1
                  ? "Finish"
                  : activeStep === 3
                    ? t("place_order")
                    : t("next")}
              </Button>
            </Box>
          </Box>
        </Box>
      </main>
    </AppContainer>
  );
};

const customerObject = {
  first_name: "",
  last_name: "",
  owner_email: "",
  country: "",
  state: "",
  city: "",
  street1: "",
  callingCode: "",
  phone: "",
  password: "",
  zipCode: "",
  subscriptionType: "Annual",
  promo_code: { code: "" },
  ip: "94.49.139.121",
  vatNumber: "",
};
const brandObject = {
  brand_name: "",
  branch_name: "",
  callingCode: "966",
  phone: "",
};
const cardObject = {
  number: "",
  month: 0,
  year: 0,
  cvv: "",
};

const useStyles = makeStyles((theme) => ({
  rootStepper: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerBox: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  customerDetails: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  orderSummary: {
    backgroundColor: "#F1F3F6",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const SUBSCRIPTION_PLANS_IDS = {
  free: "TalxGySrrQlNILJVjyEb",
  starter: "DbzXU7bLzcaDfAzsYn2e",
  plus: "bKs56wnjwMfkJ3RyQoD8",
  pro: "79CccpdNavBfAPraMuxP",
  lite: "ealXhxYIMHSa5XKK0TAz",
  soft: "wwjmH2Y7P3oJXg2GoLV7",
  danatalkhaleej: "kNVbPm9Hw2Do3UNHEHje",
  plus2years: "aoA7sXa6fbPgEVt4Ob36",
  test: "E5ZIzm36wK7J5DZf607F",
};
