import { Box, Button, CircularProgress, Fade, Grow, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Terminal } from '../assets/terminal';
import { Divider } from '../assets/divider';
import { Yearly } from '../assets/yearly';
import { Monthly } from '../assets/monthly';
import { useTranslation } from 'react-i18next';
import { AppContainer } from './Container';
import { AppPaper } from './Paper';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';

export const AppPlanDetails = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { planName } = useParams();
	const [plan, setPlan] = useState(null);
	const plansIDs = {
		free: { id: 'TalxGySrrQlNILJVjyEb', color: '#BB101C' },
		starter: { id: 'DbzXU7bLzcaDfAzsYn2e', color: '#119481' },
		plus: { id: 'bKs56wnjwMfkJ3RyQoD8', color: '#F59831' },
		lite: { id: 'ealXhxYIMHSa5XKK0TAz', color: '#F59831' },
		soft: { id: 'wwjmH2Y7P3oJXg2GoLV7', color: '#F59831' },
		pro: { id: '79CccpdNavBfAPraMuxP', color: '#BB101C' },
		danatalkhaleej: { id: 'kNVbPm9Hw2Do3UNHEHje', color: '#BB101C' },
		plus2years: { id: 'aoA7sXa6fbPgEVt4Ob36', color: '#BB101C' },
	};

	const planId = plansIDs[planName.toLowerCase()].id;
	const company = t('company');
	const title = t('details_title');

	const backButton = (
		<Button variant="outlined" onClick={() => history.goBack()} style={{ color: 'white', borderColor: 'white', fontWeight: 'bold' }}>
			{t('lang') === 'en' ? <ArrowBack /> : <ArrowForward />}
		</Button>
	);

	useEffect(() => {
		document.title = `${title} | ${company}`;
		const requestOptions = {
			method: 'GET',
			redirect: 'follow',
		};
		fetch(`https://us-central1-lazywaitposcordova.cloudfunctions.net/app/plan?plan_id=${planId}`, requestOptions)
			.then((response) => response.json())
			.then((result) => setPlan(result))
			.catch((error) => console.log('error', error));
		return () => {
			console.log('cleanup');
		};
	}, [planName, title, company, planId]);

	const features =
		plan === null ? (
			''
		) : (
			<Box m="32px" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
				<Grow in timeout={300}>
					<Box style={{ textAlign: 'center', color: '#484848' }} borderRadius="9px" p="32px" m="16px" bgcolor="#F8F8F8" width="130px">
						<Terminal color={plansIDs[planName.toLowerCase()].color} />
						<Typography style={{ color: '#484848' }} variant="h6">
							{t('terminals')}
						</Typography>
						<Typography style={{ color: 'rgb(72 72 72 / 0.48)' }} variant="body1">
							{plan.allowed_terminals}
						</Typography>
					</Box>
				</Grow>
				<Grow in timeout={600}>
					<Box style={{ textAlign: 'center', color: '#484848' }} borderRadius="9px" p="32px" m="16px" bgcolor="#F8F8F8" width="130px">
						<Yearly color={plansIDs[planName.toLowerCase()].color} />
						<Typography style={{ color: '#484848' }} variant="h6">
							{t('yearly_price')}
						</Typography>
						<Typography style={{ color: 'rgb(72 72 72 / 0.48)' }} variant="body1">
							{plan.yearly_price}
						</Typography>
					</Box>
				</Grow>
				{/* <Grow in timeout={900}>
          <Box
            style={{ textAlign: "center" }}
            borderRadius="9px"
            p="32px"
            m="16px"
            bgcolor="#F8F8F8"
            width="130px"
          >
            <Monthly color={plansIDs[planName.toLowerCase()].color} />
            <Typography style={{ color: "#484848" }} variant="h6">
              {t("monthly_price")}
            </Typography>
            <Typography
              style={{ color: "rgb(72 72 72 / 0.48)" }}
              variant="body1"
            >
              {plan.monthly_price}
            </Typography>
          </Box>
        </Grow> */}
			</Box>
		);

	return plan === null ? (
		<AppContainer>
			<AppPaper>
				<Box position="relative" m="32px" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
					<CircularProgress />
				</Box>
			</AppPaper>
		</AppContainer>
	) : (
		<AppContainer>
			<Box display="flex" justifyContent="flex-start">
				{backButton}
			</Box>
			<AppPaper>
				<Box>
					<Box p="32px 32px 3px 64px">
						<Typography
							variant="h4"
							style={{
								fontWeight: 'bold',
								color: plansIDs[planName.toLowerCase()].color,
							}}
							color="secondary"
						>
							{t('lang') === 'en' ? plan.en : plan.ar}
						</Typography>
					</Box>
					<Divider color={plansIDs[planName.toLowerCase()].color} />
					{features}
					<Box p="32px 32px 3px 64px">
						<Typography
							variant="h5"
							style={{
								fontWeight: 'bold',
								color: plansIDs[planName.toLowerCase()].color,
							}}
							color="secondary"
						>
							{t('plan_options')}
						</Typography>
					</Box>
					<Fade in timeout={1100}>
						<Box p="3px 32px 32px 64px">
							{plan.plan_options.map((option, index) => {
								return <Typography key={index}>{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>;
							})}
						</Box>
					</Fade>
				</Box>
			</AppPaper>
		</AppContainer>
	);
};
