export const FailureSVG = () => <svg xmlns="http://www.w3.org/2000/svg" width="216.989" height="121" viewBox="0 0 216.989 121">
    <g id="Group_17733" data-name="Group 17733" transform="translate(-597 -404)">
        <path id="Path_2039" data-name="Path 2039" d="M85.1,56.424a12.827,12.827,0,0,0-4.178.707c.006-.235.017-.472.017-.707A25.778,25.778,0,0,0,31.611,45.941,19.993,19.993,0,1,0,19.994,82.2H85.1a12.89,12.89,0,0,0,0-25.779" transform="translate(716 395.797)" fill="#f3f3f3" />
        <circle id="Ellipse_148" data-name="Ellipse 148" cx="60.5" cy="60.5" r="60.5" transform="translate(656 404)" fill="#ef5350" />
        <path id="ic_done_24px" d="M16.066,47.7l5.14,5.046L61.733,10.525,57.005,5.6Z" transform="translate(676.934 435.4)" fill="#fff" />
        <path id="Path_2027" data-name="Path 2027" d="M85.1,56.424a12.827,12.827,0,0,0-4.178.707c.006-.235.017-.472.017-.707A25.778,25.778,0,0,0,31.611,45.941,19.993,19.993,0,1,0,19.994,82.2H85.1a12.89,12.89,0,0,0,0-25.779" transform="translate(606 436.797)" fill="#f3f3f3" />
        <path id="Path_2038" data-name="Path 2038" d="M85.1,56.424a12.827,12.827,0,0,0-4.178.707c.006-.235.017-.472.017-.707A25.778,25.778,0,0,0,31.611,45.941,19.993,19.993,0,1,0,19.994,82.2H85.1a12.89,12.89,0,0,0,0-25.779" transform="translate(597 431.797)" fill="#fff" />
        <path id="ic_done_24px-2" data-name="ic_done_24px" d="M0,40.441l5.349,4.847L47.523,4.732,42.6,0Z" transform="translate(693 488.523) rotate(-90)" fill="#fff" />
    </g>
</svg>


