import { Grid } from "@material-ui/core"
import { AppContainer } from "./Container"
import paymentMethodsPNG from "../assets/supported-payment-methods.png";

export const AppFooter = () => {
    return (
        <AppContainer>
            <Grid component="footer" container direction="row" justify="space-between" alignItems="center" style={{ margin: "45px 0 16px 0", direction: "ltr" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="381" height="42" viewBox="0 0 381 42">
                    <text id="_CloudAppsLLC.All_rights_reserved." data-name="@CloudAppsLLC.All rights reserved." transform="translate(0 38)" fill="#a5abb2" fontSize="18" fontFamily="Helvetica">
                        <tspan x="0" y="0">@CloudAppsLLC.All rights reserved.</tspan>
                    </text>
                    <text id="LazyWait_our_comprehensive_technological_solutions_" data-name="LazyWait, our comprehensive technological solutions " transform="translate(0 12)" fill="#7d7d7d" fontSize="16" fontFamily="Helvetica">
                        <tspan x="0" y="0">LazyWait, our comprehensive technological solutions{" "}</tspan>
                    </text>
                </svg>
                <img alt="Payment methods" src={paymentMethodsPNG} />
            </Grid>
        </AppContainer>
    )
}