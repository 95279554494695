import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getClientInfo,
  getClientIp,
  getAllCountries,
  getBranch,
} from "../api/API";
import { AppPlan } from "./Plan";
import { isEmpty } from "../js/utils";

export const AppCustomerDetailsForm = ({
  formObj,
  setFormObj,
  setPrice,
  formErrors,
}) => {
  const classes = useStyles();
  const { planName, branchId, clientId } = useParams();
  const { t } = useTranslation();
  const handleChange = (obj) => setFormObj({ ...formObj, ...obj });
  const selectCountry = (country) => {
    const code = countriesList.filter((c) => c.alpha3Code === country);
    if (code.length > 0) {
      handleChange({ country: country, callingCode: code[0].callingCodes[0] });
    }
  };
  const [countriesList, setCountriesList] = useState([]);
  const [clientIp, setClientIp] = useState("94.49.139.121");

  const onSignInSubmit = (c) => {
    console.log(c);
  };

  useEffect(() => {
    (async () => {
      setCountriesList(await getAllCountries());
      setClientIp(await getClientIp());
      if (clientId !== undefined && branchId !== undefined) {
        const clientInfo = await getClientInfo(clientId);
        let branchInfo = {}
        if(branchId != "new"){
          branchInfo = await (await getBranch(clientId, branchId)).json();
          console.log(clientId, branchId);
          console.log(branchInfo);
        }


        setFormObj({
          first_name: clientInfo.first_name,
          last_name: clientInfo.last_name,
          owner_email: clientInfo.owner_email,
          country: clientInfo.country || "SAU",
          state: clientInfo.state,
          city: clientInfo.city,
          street1: "",
          callingCode: "966",
          phone: clientInfo.owner_phone,
          zipCode: clientInfo.zip_code,
          subscriptionType: "Annual",
          promo_code: { code: "" },
          ip: clientIp,
          vatNumber: (branchInfo? branchInfo.vatNumber: ''),
        });
      }
    })();
  }, [branchId, clientId, setFormObj, clientIp]);

  return (
    <Grid
      style={{ padding: 24 }}
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item>
        <div id="sign-in-button"></div>
        <AppPlan
          setPrice={setPrice}
          subscriptionType={formObj.subscriptionType}
        />
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      >
        {planName !== "free" ? (
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("subscription_type")}</FormLabel>
            <RadioGroup
              color="primary"
              row={true}
              onChange={(e) => {
                handleChange({ subscriptionType: e.target.value });
              }}
            >
              <FormControlLabel
                control={
                  <Radio
                    value="Annual"
                    checked={
                      formObj.subscriptionType.toLowerCase() === "annual"
                    }
                  />
                }
                label={t("annual")}
              />

              {/*
              ASAD suspending the monthly payment
              <FormControlLabel
                control={
                  <Radio
                    value="Monthly"
                    checked={formObj.subscriptionType.toLowerCase() === "monthly"}
                  />
                }
                label={t("monthly")}
              /> */}
            </RadioGroup>
          </FormControl>
        ) : (
          ""
        )}
      </Grid>
      <Grid item>
        <FormControl fullWidth={true} style={{ color: "red" }}>
          <TextField
            className={formErrors.first_name ? classes.inputError : ""}
            required
            onChange={(e) => handleChange({ first_name: e.target.value })}
            value={formObj.first_name}
            type="text"
            label={t("first_name")}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth={true}>
          <TextField
            className={formErrors.last_name ? classes.inputError : ""}
            required
            onChange={(e) => handleChange({ last_name: e.target.value })}
            value={formObj.last_name}
            type="text"
            label={t("last_name")}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth={true}>
          <TextField
            className={formErrors.owner_email ? classes.inputError : ""}
            required
            onChange={(e) => handleChange({ owner_email: e.target.value })}
            value={formObj.owner_email}
            type="email"
            label={t("email")}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth={true}>
          <TextField
            className={formErrors.vatNumber ? classes.inputError : ""}
            onChange={(e) => handleChange({ vatNumber: e.target.value })}
            value={formObj.vatNumber}
            type="text"
            label={t("vatNumber")}
            variant="outlined"
          />
          {isEmpty(formObj.vatNumber) && (<label style={{ padding: 5, color:"crimson", fontWeight:"bold"}}>{t("TAX_NUMBER_MESSEGE")}</label>)}
        </FormControl>
      </Grid>
      {!clientId ? (
        <Grid item>
          <FormControl fullWidth={true}>
            <TextField
              className={formErrors.password ? classes.inputError : ""}
              required
              onChange={(e) => handleChange({ password: e.target.value })}
              value={formObj.password}
              type="password"
              label={t("password") + t("min6")}
              variant="outlined"
            />
          </FormControl>
        </Grid>
      ) : (
        ""
      )}
      <Grid item>
        {countriesList && countriesList.length !== 0 ? (
          <FormControl
            className={formErrors.country ? classes.inputError : ""}
            variant="outlined"
            fullWidth
          >
            <InputLabel id="countries">{t("country")}</InputLabel>
            <Select
              className={formErrors.country ? classes.inputError : ""}
              labelId="countries"
              value={formObj.country}
              onChange={(e) => selectCountry(e.target.value)}
            >
              {countriesList.map((c) => (
                <MenuItem key={c.alpha3Code} value={c.alpha3Code}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <CircularProgress />
        )}
      </Grid>
      <Grid item>
        <Box display="flex" flexDirection="row">
          <Box>
            <FormControl style={{ width: 110 }}>
              <TextField
                className={formErrors.callingCode ? classes.inputError : ""}
                required
                onChange={(e) => handleChange({ callingCode: e.target.value })}
                value={formObj.callingCode}
                type="text"
                label={t("calling_code")}
                variant="outlined"
              />
            </FormControl>
          </Box>
          <span style={{ padding: "0 5px 0 5px" }} />
          <Box style={{ width: "100%" }}>
            <FormControl fullWidth>
              <TextField
                className={formErrors.phone ? classes.inputError : ""}
                required
                onChange={(e) => handleChange({ phone: e.target.value })}
                value={formObj.phone}
                type="tel"
                label={t("phone")}
                variant="outlined"
              />
            </FormControl>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <FormControl fullWidth={true}>
          <TextField
            className={formErrors.state ? classes.inputError : ""}
            required
            onChange={(e) => handleChange({ state: e.target.value })}
            value={formObj.state}
            type="text"
            label={t("state")}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth={true}>
          <TextField
            className={formErrors.city ? classes.inputError : ""}
            required
            onChange={(e) => handleChange({ city: e.target.value })}
            value={formObj.city}
            type="text"
            label={t("city")}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth={true}>
          <TextField
            className={formErrors.street1 ? classes.inputError : ""}
            required
            onChange={(e) => handleChange({ street1: e.target.value })}
            value={formObj.street1}
            type="text"
            label={t("street1")}
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth={true}>
          <TextField
            className={formErrors.zipCode ? classes.inputError : ""}
            required
            onChange={(e) => handleChange({ zipCode: e.target.value })}
            value={formObj.zipCode}
            type="text"
            label={t("zip")}
            variant="outlined"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  inputError: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
    },
    // "& .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    // "&:hover .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    "& .MuiInputLabel-outlined": {
      color: "red",
    },
    // "&:hover .MuiInputLabel-outlined": {
    //   color: "red",
    // },
    // "& .MuiInputLabel-outlined.Mui-focused": {
    //   color: "red",
    // },
  },
});
