import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getAllCategories, getAllSubCategories } from "../api/API";
import { AppPlan } from "./Plan";

export const AppBrandDetailsForm = ({
  formObj,
  setFormObj,
  setPrice,
  formErrors,
}) => {
  const classes = useStyles();
  const { branchId, clientId } = useParams();
  const { t } = useTranslation();
  const handleChange = (obj) => {
    setFormObj({ ...formObj, ...obj });
  };
  const handleSubCategoris = (obj) => {
    handleChange(obj);
    categoriesList.forEach(async (c) => {
      if (c.name == obj.category && c.category_id) {
        console.log(c, subCategoriesList);
        setSubCategoriesList(await getAllSubCategories(c.category_id));
      }
    });
  };
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [paymentType, setPaymentType] = useState("new");

  useEffect(() => {
    (async () => {
      const categories = await getAllCategories();
      setCategoriesList(categories);
    })();
  }, []);

  return (
    <Grid
      style={{ padding: 24 }}
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={2}
    >
      {/* <Grid item>
        <AppPlan
          setPrice={setPrice}
          subscriptionType={formObj.subscriptionType}
        />
      </Grid> */}
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      ></Grid>
{/* 
      <Box p="24px" className={classes.customerDetails}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{t("payment_type")}</FormLabel>
          <RadioGroup
            aria-label="payment method"
            name="payment_type"
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
          >
            <FormControlLabel
              value="new"
              control={<Radio />}
              label={t("new_branch")}
            />
            <FormControlLabel
              value="Existitng"
              control={<Radio />}
              label={t("existitng_branch")}
            />
          </RadioGroup>
        </FormControl>
      </Box> */}

      {paymentType == "new" ? (
        <Grid
          style={{ padding: 24 }}
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item>
            <FormControl fullWidth={true} style={{ color: "red" }}>
              <TextField
                className={formErrors.brand_name ? classes.inputError : ""}
                required
                onChange={(e) => handleChange({ brand_name: e.target.value })}
                value={formObj.brand_name}
                type="text"
                label={t("brand_name")}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth={true}>
              <TextField
                className={formErrors.branch_name ? classes.inputError : ""}
                required
                onChange={(e) => handleChange({ branch_name: e.target.value })}
                value={formObj.branch_name}
                type="text"
                label={t("branch_name")}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item>
            {categoriesList && categoriesList.length !== 0 ? (
              <FormControl
                className={formErrors.category ? classes.inputError : ""}
                variant="outlined"
                fullWidth
              >
                <InputLabel id="categories">{t("category")}</InputLabel>
                <Select
                  className={formErrors.category ? classes.inputError : ""}
                  labelId="countries"
                  required
                  value={formObj.category}
                  onChange={(e) =>
                    handleSubCategoris({ category: e.target.value })
                  }
                >
                  {categoriesList.map((c) => (
                    <MenuItem key={c.category_id} value={c.name}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item>
            <FormControl
              className={formErrors.sub_category ? classes.inputError : ""}
              variant="outlined"
              fullWidth
            >
              <InputLabel id="sub_categories">{t("sub_category")}</InputLabel>
              <Select
                className={formErrors.sub_category ? classes.inputError : ""}
                labelId="countries"
                disabled={subCategoriesList.length == 0}
                required
                value={formObj.sub_category}
                onChange={(e) => handleChange({ sub_category: e.target.value })}
              >
                {subCategoriesList.map((c) => (
                  <MenuItem key={c.category_id} value={c.name}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection="row">
              <Box>
                <FormControl style={{ width: 110 }}>
                  <TextField
                    className={formErrors.callingCode ? classes.inputError : ""}
                    required
                    onChange={(e) =>
                      handleChange({ callingCode: e.target.value })
                    }
                    value={formObj.callingCode}
                    type="text"
                    label={t("calling_code")}
                    variant="outlined"
                  />
                </FormControl>
              </Box>
              <span style={{ padding: "0 5px 0 5px" }} />
              <Box style={{ width: "100%" }}>
                <FormControl fullWidth>
                  <TextField
                    className={formErrors.phone ? classes.inputError : ""}
                    required
                    onChange={(e) => handleChange({ phone: e.target.value })}
                    value={formObj.phone}
                    type="tel"
                    label={t("phone")}
                    variant="outlined"
                  />
                </FormControl>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : 
	  
	  //List Branches
	  <div>dfsdf</div>
	  
	  
	  
	  
	  }
    </Grid>
  );
};

const useStyles = makeStyles({
  inputError: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    // "& .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    // "&:hover .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    "& .MuiInputLabel-outlined": {
      color: "red",
    },
    // "&:hover .MuiInputLabel-outlined": {
    //   color: "red",
    // },
    // "& .MuiInputLabel-outlined.Mui-focused": {
    //   color: "red",
    // },
  },
});
