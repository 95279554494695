import { Dialog, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useTranslation } from "react-i18next";


export const AppHadTrialDialog = (props) => {
    const {t} = useTranslation();
    const { onClose, open } = props;
    const handleClose = () => {
        onClose();
      };
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle style={{textAlign: "center"}}>{t("had_trial")}</DialogTitle>
        </Dialog>
    )
}