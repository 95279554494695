import React, { useState } from "react";
import {
  Grid,
  Box,
  FormControl,
  TextField,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { detectCardType } from "../js/utils";
import madaSVG from "../assets/mada.svg";
import mastercardSVG from "../assets/mastercard.svg";
import maestroSVG from "../assets/maestro.svg";
import visaSVG from "../assets/visa.svg";
import dotsPNG from "../assets/dots.png";
import { useTranslation } from "react-i18next";

export const CardDetailsForm = ({ formObj, setFormObj, formErrors, payTabsError }) => {
  const { t } = useTranslation();
  const cardsLogos = {
    mada: madaSVG,
    mastercard: mastercardSVG,
    maestro: maestroSVG,
    visa: visaSVG,
  };
  const classes = useStyles();
  const [matchedCards, setMatchedCards] = useState([]);
  const handleChange = (obj) => {
    setFormObj({ ...formObj, ...obj });
  };

  return (
    <Grid
      style={{ padding: 24, direction: "ltr" }}
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={2}
    >
      {payTabsError ? <Grid item>
        <Typography color="error" variant="button">{payTabsError.errorText}</Typography>
      </Grid> : null}
      <Grid item container direction="row" spacing={1} wrap="nowrap">
        <Grid item style={{ width: "70%" }}>
          <FormControl fullWidth={true}>
            <TextField
              className={formErrors.number ? classes.inputError : ""}
              onChange={(e) => {
                handleChange({ number: e.target.value });
                setMatchedCards(detectCardType(e.target.value));
              }}
              placeholder="e.g 5294XXXXXXXXXXXX"
              required
              type="text"
              label={t("card_number")}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid
          style={{ width: "30%" }}
          item
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justify="center"
        >
          {matchedCards.length !== 0 ? (
            matchedCards.map((card) => (
              <Grid item key={card.type}>
                {cardsLogos[card.type] ? (
                  <img
                    height={20}
                    alt="mastercard"
                    src={cardsLogos[card.type]}
                  />
                ) : (
                  card.type
                )}
              </Grid>
            ))
          ) : (
            <Grid item>
              <img height={38} alt="placeholder dots" src={dotsPNG} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Box display="flex" flexDirection="row">
          <FormControl fullWidth={true}>
            <TextField
              placeholder="e.g 06"
              className={formErrors.month ? classes.inputError : ""}
              onChange={(e) =>
                handleChange({ month: parseInt(e.target.value) })
              }
              required
              type="text"
              label={t("month")}
              variant="outlined"
            />
          </FormControl>
          <span style={{ margin: "0 5px 0 5px" }}></span>
          <FormControl fullWidth={true}>
            <TextField
              placeholder="e.g 2022"
              className={formErrors.year ? classes.inputError : ""}
              onChange={(e) => handleChange({ year: parseInt(e.target.value) })}
              required
              type="text"
              label={t("year")}
              variant="outlined"
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item>
        <FormControl fullWidth={true}>
          <TextField
            placeholder="e.g 123"
            className={formErrors.cvv ? classes.inputError : ""}
            onChange={(e) => handleChange({ cvv: e.target.value })}
            required
            type="text"
            label={t("cvv")}
            variant="outlined"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  inputError: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    // "& .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    // "&:hover .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    "& .MuiInputLabel-outlined": {
      color: "red",
    },
    // "&:hover .MuiInputLabel-outlined": {
    //   color: "red",
    // },
    // "& .MuiInputLabel-outlined.Mui-focused": {
    //   color: "red",
    // },
  },
});