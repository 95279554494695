export function isEmpty(txt) {
  txt = txt && typeof txt == "string" ? txt.trim() : txt;
  if (
    txt === "" ||
    txt === null ||
    txt === " " ||
    txt === undefined ||
    txt === "undefined" ||
    Object.keys(txt).length == 0
  ) {
    return true;
  }
  return false;
}


export const isCardNumberValid = (text) => {
  const detectedCards = detectCardType(text);
  if (!text || detectedCards.length === 0) return false;
  return true;
};

export const isCVVNumberValid = (text) => {
  const cvvLength = 3;
  if (!text || text.length < cvvLength || text.length > cvvLength) return false;
  return true;
};

export const isCardMonthValid = (num) => {
  const isInRange = num > 0 && num < 13;
  if (!num || !isInRange) return false;
  return true;
};

export const isCardYearValid = (num) => {
  const isInRange = num >= new Date().getFullYear() && num < 2100;
  if (!num || !isInRange) return false;
  return true;
};

export const CARDS = [
  { type: "meeza", pattern: /^507803/, cvv: 3, luhn: true },
  { type: "meeza", pattern: /^507808/, cvv: 3, luhn: true },
  { type: "meeza", pattern: /^507809/, cvv: 3, luhn: true },
  { type: "meeza", pattern: /^507810/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^400282/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^402004/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^403803/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^404029/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^407545/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^410469/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^413298/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^419244/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^419291/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^422610/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^422681/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^422820/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^422821/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^422822/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^422823/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^426371/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^426372/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^426681/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^428257/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^432410/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^432415/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^433084/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^433236/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^433829/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^435949/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^437425/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^437426/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^437427/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^437569/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^439357/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^447168/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^456595/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^464156/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^464157/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^464175/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^464426/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^467362/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^473820/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^483791/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^484130/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^484131/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^484172/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^489091/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^490907/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^510723/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^515722/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^523672/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^524278/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^528647/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^528669/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^533117/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^534417/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^535981/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^536028/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^539150/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^542160/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^549184/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^559071/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^559406/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^559407/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^559753/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^588855/, cvv: 3, luhn: true },
  { type: "omannet", pattern: /^601722/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^401757/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^407197/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^407395/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^409201/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^410685/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^417633/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^419593/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^422817/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^422818/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^422819/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^428331/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^428671/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^428672/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^428673/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^431361/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^432328/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^434107/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^439954/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^440533/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^440647/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^440795/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^445564/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^446393/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^446404/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^446672/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^455036/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^455708/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^457865/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^458456/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^462220/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^468540/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^468541/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^468542/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^468543/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^483010/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^483011/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^483012/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^486094/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^486095/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^486096/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^489318/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^489319/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^493428/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^504300/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^508160/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^513213/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^520058/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^521076/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^524130/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^524514/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^529415/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^529741/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^530060/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^530906/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^531095/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^531196/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^532013/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^535825/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^535989/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^536023/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^537767/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^539931/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^543085/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^543357/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^549760/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^554180/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^557606/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^558848/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^585265/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^588845/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^588846/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^588848/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^588849/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^588850/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^588982/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^588983/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^589005/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^589206/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^604906/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^605141/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^636120/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968201/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968202/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968203/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968204/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968205/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968206/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968207/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968208/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968209/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968210/, cvv: 3, luhn: true },
  { type: "mada", pattern: /^968211/, cvv: 3, luhn: true },
  { type: "jcb", pattern: /^1800/, cvv: 3, luhn: true },
  { type: "jcb", pattern: /^2131/, cvv: 3, luhn: true },
  { type: "mastercard", pattern: /^222[123456789]/, cvv: 3, luhn: true },
  { type: "mastercard", pattern: /^22[3456789]/, cvv: 3, luhn: true },
  { type: "mastercard", pattern: /^2[3456]/, cvv: 3, luhn: true },
  { type: "mastercard", pattern: /^27[01]/, cvv: 3, luhn: true },
  { type: "mastercard", pattern: /^2720/, cvv: 3, luhn: true },
  { type: "diners", pattern: /^30[0-5]/, cvv: 3, luhn: true },
  { type: "amex", pattern: /^3[47]/, cvv: 4, luhn: true },
  { type: "jcb", pattern: /^352[89]/, cvv: 3, luhn: true },
  { type: "jcb", pattern: /^35[345678]/, cvv: 3, luhn: true },
  { type: "diners", pattern: /^36/, cvv: 3, luhn: true },
  { type: "diners", pattern: /^38/, cvv: 3, luhn: true },
  { type: "electron", pattern: /^4026/, cvv: 3, luhn: true },
  { type: "electron", pattern: /^417500/, cvv: 3, luhn: true },
  { type: "electron", pattern: /^4405/, cvv: 3, luhn: true },
  { type: "electron", pattern: /^4508/, cvv: 3, luhn: true },
  { type: "electron", pattern: /^4844/, cvv: 3, luhn: true },
  { type: "electron", pattern: /^491[37]/, cvv: 3, luhn: true },
  { type: "visa", pattern: /^4/, cvv: 3, luhn: true },
  { type: "maestro", pattern: /^5018/, cvv: 3, luhn: true },
  { type: "dankort", pattern: /^5019/, cvv: 3, luhn: true },
  { type: "maestro", pattern: /^5020/, cvv: 3, luhn: true },
  { type: "maestro", pattern: /^5038/, cvv: 3, luhn: true },
  { type: "rupay", pattern: /^508[56789]/, cvv: 3, luhn: true },
  { type: "mastercard", pattern: /^5/, cvv: 3, luhn: true },
  { type: "rupay", pattern: /^606[123456789]/, cvv: 3, luhn: true },
  { type: "rupay", pattern: /^60[78]/, cvv: 3, luhn: true },
  { type: "discover", pattern: /^6011/, cvv: 3, luhn: true },
  { type: "cup", pattern: /^62[24568]/, cvv: 3, luhn: false },
  { type: "cup", pattern: /^81719/, cvv: 3, luhn: false },
  { type: "rupay", pattern: /^627387/, cvv: 3, luhn: true },
  { type: "maestro", pattern: /^6304/, cvv: 3, luhn: true },
  { type: "discover", pattern: /^64[4-9]/, cvv: 3, luhn: true },
  { type: "rupay", pattern: /^6521[56789]/, cvv: 3, luhn: true },
  { type: "rupay", pattern: /^652[23456789]/, cvv: 3, luhn: true },
  { type: "rupay", pattern: /^6530/, cvv: 3, luhn: true },
  { type: "rupay", pattern: /^6531[01234]/, cvv: 3, luhn: true },
  { type: "discover", pattern: /^65/, cvv: 3, luhn: true },
  { type: "maestro", pattern: /^6703/, cvv: 3, luhn: true },
  { type: "maestro", pattern: /^6706/, cvv: 3, luhn: true },
  { type: "maestro", pattern: /^6709/, cvv: 3, luhn: true },
  { type: "maestro", pattern: /^6759/, cvv: 3, luhn: true },
  { type: "maestro", pattern: /^676[1-3]/, cvv: 3, luhn: true },
  { type: "maestro", pattern: /^6771/, cvv: 3, luhn: true },
];

export const detectCardType = (pan) => {
  const matches = [];
  CARDS.forEach((card) => {
    if (card.pattern.test(pan)) matches.push(card);
  });
  return matches;
};
