import {
  Box,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getBranch, GetPromoCode } from "../api/API";
import LocationPersonPin from "../assets/LocationPersonPin.svg";
import { isEmpty } from "../js/utils";

export const AppOrderSummary = ({
  formObj,
  setFormObj,
  price,
  setPrice,
  discount,
  setDiscount,
  plan_id,
  promoCode,
  setPromoCode,
  promoCodeId,
  setPromoCodeId,
}) => {
  const { t } = useTranslation();
  const handleChange = (obj) => setFormObj({ ...formObj, ...obj });
  const { clientId, branchId } = useParams();
  const [branch, setBranch] = useState();
  const [logo, setLogo] = useState();
  const classes = useStyles();

  if (!price) {
    setPrice(0);
  }
  const subtotal = price / 1.15 - discount;
  const VATAmount = subtotal * 0.15;
  let rows = [
    createReceipt(t("subtotal"), subtotal.toFixed(2) + " " + t("currency")),
    createReceipt(t("vat15"), `${VATAmount.toFixed(2)} ` + t("currency")),
  ];

  if (promoCode) {
    rows.push(
      createReceipt(t("promotion_code"), promoCode.toUpperCase() + " ")
    );
    rows.push(
      createReceipt(t("promotion_discount"), discount + " " + t("currency"))
    );
  }
  rows.push(
    createReceipt(
      t("grand_total"),
      (subtotal + VATAmount).toFixed(2) + " " + t("currency")
    )
  );

  const handlePromo = async () => {
    const temp_promo = formObj.promo_code.code
      ? formObj.promo_code.code
      : promoCode;
    const promo = await GetPromoCode(
      temp_promo,
      plan_id,
      formObj.subscriptionType
    );
    if (promo && promo.amount > 1) {
      console.log(temp_promo);
      handleChange({ promo_code: promo });
      setDiscount(promo.amount);
      setPromoCodeId(promo.promo_code_id);
      setPromoCode(temp_promo);
    } else if (promo && promo.amount > 0 && promo.amount <= 1) {
      console.log(promo);
      setDiscount(subtotal * promo.amount);
      handleChange({ promo_code: promo });
      setPromoCodeId(promo.promo_code_id || "");
      setPromoCode(temp_promo);
    } else {
      console.log(promo);
      setDiscount(0);
      handleChange({ promo_code: promo || null });
      setPromoCodeId(promo.promo_code_id || "");
      setPromoCode(isEmpty(promo) == false ? temp_promo : null);
    }
  };

  useEffect(() => {
    console.log(promoCode);
    if (promoCode) {
      handlePromo();
    }
    if (clientId && branchId && branchId != "new") {
      getBranch(clientId, branchId)
        .then((response) => response.json())
        .then((result) => {
          setBranch(result);
          setLogo(result.logo);
          console.log(result.logo);
        })
        .catch((error) => console.log("error @ getBranch", error));
    }
  }, [clientId, branchId, formObj.subscriptionType]);

  return (
    <Box p="32px" className="root">
      {/* <Grid container direction="row" justify="center" alignItems="center" spacing={1} style={{ width: "100%" }}>
        <Grid item>
          <FormControl fullWidth={true}>
            <TextField
              style={{ background: "white" }}
              variant="outlined"
              onChange={(e) => handleChange({ promoCode: e.target.value })}
              value={formObj.promoCode}
              id="promo"
              type="text"
              label={t("promo_code")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            style={{
              background: "#B5B5B5",
              color: "white",
              borderRadius: "6px",
              height: "54px",
            }}
          >
            {t("apply")}
          </Button>
        </Grid>
      </Grid>
      <Box margin="35px 0 35px 0">
        <DividerSVG color="#098A77" />
      </Box> */}
      {branch ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginBottom={4}
        >
          <img
            height={150}
            src={logo}
            alt="Branch logo"
            onError={() => {
              setLogo(LocationPersonPin);
            }}
          />
          <Typography
            style={{ fontSize: 12, fontWeight: "bold" }}
            variant="button"
          >
            {branch.name_lan_p} {t("branch")}
          </Typography>
          <Typography variant="body1">{branch.address}</Typography>
        </Box>
      ) : null}

      {price > 0 ? (
        <Box display="flex" style={{ marginTop: "10px", marginBottom: "10px" }}>
          <TextField
            style={{ margin: "5px" }}
            onChange={(e) =>
              handleChange({ promo_code: { code: e.target.value } })
            }
            value={formObj.promo_code.code}
            type="text"
            label={t("promo_code")}
            variant="outlined"
          />
          <Button
            onClick={handlePromo}
            style={{ color: "#fff", backgroundColor: "#03826F", margin: "5px" }}
          >
            {t("apply")}
          </Button>
        </Box>
      ) : null}

      <Typography variant="button">{t("order_summary")}</Typography>
      <Box display="block">
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" className={classes.th} scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.td} align="right">
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

const createReceipt = (name, value) => {
  return { name, value };
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      margin: "16px",
      borderRadius: "4px",
    },
  },
  table: {
    minWidth: "100%",
  },
  th: {
    fontWeight: "bold",
    borderBottom: "0",
  },
  td: {
    borderBottom: "0",
  },
  placeOrder: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
