import { Box, Button, Card, CircularProgress, Grid, Grow, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AddonMark } from '../assets/addonMark';
import { CheckMark, GradeOutCheckMark } from '../assets/checkMark';
import { Price } from '../assets/price';
import '../App.css';
import { useTranslation } from 'react-i18next';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { AppContainer } from './Container';
import { AppPaper } from './Paper';

export const AppPlans = () => {
	const { planName, clientId, branchId } = useParams();
	const history = useHistory();
	const { t } = useTranslation();
	const [result, setResult] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const company = t('company');
	const title = t('plans_title');

	const sortResult = (res) => {
		return res.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
	};

	function getNotIncludedFeatures(subPackage, fullPackage) {
		if (subPackage && subPackage.plan_options && fullPackage && fullPackage.plan_options) {
			const notIncludedFeatures = [];
			fullPackage.plan_options.forEach((feature) => {
				const includedFeature = subPackage.plan_options.find((subFaeture) => subFaeture.option_title_en === feature.option_title_en);
				if (!includedFeature) {
					notIncludedFeatures.push(feature);
				}
			});
			return notIncludedFeatures.sort((a, b) => {
				if (a.optionID && b.optionID) {
					return Number(a.optionID) > Number(b.optionID) ? 1 : Number(b.optionID) > Number(a.optionID) ? -1 : 0;
				}
				return 0;
			});
		} else {
			return [];
		}
	}
	const DanatAlkhaleejOptions = () => {
		const pkg = result && result.find((p) => p.en.toLowerCase() === 'danatalkhaleej');

		return pkg && pkg.plan_options ? (
			<Box display="flex" flexDirection="column" justifyContent="flex-start" color="#909090">
				{pkg.plan_options
					.sort((a, b) => {
						if (a.optionID && b.optionID) {
							return Number(a.optionID) > Number(b.optionID) ? 1 : Number(b.optionID) > Number(a.optionID) ? -1 : 0;
						}
						return 0;
					})
					.map((option, index) => (
						<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
							<Box m="6px">
								<CheckMark />
							</Box>
							<Box m="5px">
								<Typography variant="body2">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
							</Box>
						</Box>
					))}
			</Box>
		) : (
			''
		);
	};

	const starterOptions = () => {
		const pkg = result && result.find((p) => p.en.toLowerCase() === 'starter');
		const proPackage = result && result.find((p) => p.en.toLowerCase() === 'pro');
		const notIcludedFeatures = getNotIncludedFeatures(pkg, proPackage);
		return pkg && pkg.plan_options ? (
			<Box display="flex" flexDirection="column" justifyContent="flex-start" color="#909090">
				{pkg.plan_options
					.sort((a, b) => {
						if (a.optionID && b.optionID) {
							return Number(a.optionID) > Number(b.optionID) ? 1 : Number(b.optionID) > Number(a.optionID) ? -1 : 0;
						}
						return 0;
					})
					.map((option, index) => (
						<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
							<Box m="6px">
								<CheckMark />
							</Box>
							<Box m="5px">
								<Typography variant="body1">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
							</Box>
						</Box>
					))}
				{notIcludedFeatures.map((option) => (
					<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
						<Box m="6px">
							<GradeOutCheckMark />
						</Box>
						<Box m="5px">
							<Typography variant="body2">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
						</Box>
					</Box>
				))}
			</Box>
		) : (
			''
		);
	};
	const softOptions = () => {
		const pkg = result && result.find((p) => p.en.toLowerCase() === 'soft');
		const proPackage = result && result.find((p) => p.en.toLowerCase() === 'pro');
		const notIcludedFeatures = getNotIncludedFeatures(pkg, proPackage);
		return pkg && pkg.plan_options ? (
			<Box display="flex" flexDirection="column" justifyContent="flex-start" color="#909090">
				{pkg.plan_options
					.sort((a, b) => {
						if (a.optionID && b.optionID) {
							return Number(a.optionID) > Number(b.optionID) ? 1 : Number(b.optionID) > Number(a.optionID) ? -1 : 0;
						}
						return 0;
					})
					.map((option, index) => (
						<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
							<Box m="6px">
								<CheckMark />
							</Box>
							<Box m="5px">
								<Typography variant="body1">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
							</Box>
						</Box>
					))}
				{notIcludedFeatures.map((option) => (
					<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
						<Box m="6px">
							<GradeOutCheckMark />
						</Box>
						<Box m="5px">
							<Typography variant="body2">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
						</Box>
					</Box>
				))}
			</Box>
		) : (
			''
		);
	};

	const liteOptions = () => {
		const pkg = result && result.find((p) => p.en.toLowerCase() === 'lite');
		const proPackage = result && result.find((p) => p.en.toLowerCase() === 'pro');
		const notIcludedFeatures = getNotIncludedFeatures(pkg, proPackage);
		return pkg && pkg.plan_options ? (
			<Box display="flex" flexDirection="column" justifyContent="flex-start" color="#909090">
				{pkg.plan_options
					.sort((a, b) => {
						if (a.optionID && b.optionID) {
							return Number(a.optionID) > Number(b.optionID) ? 1 : Number(b.optionID) > Number(a.optionID) ? -1 : 0;
						}
						return 0;
					})
					.map((option, index) => (
						<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
							<Box m="6px">
								<CheckMark />
							</Box>
							<Box m="5px">
								<Typography variant="body1">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
							</Box>
						</Box>
					))}
				{notIcludedFeatures.map((option) => (
					<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
						<Box m="6px">
							<GradeOutCheckMark />
						</Box>
						<Box m="5px">
							<Typography variant="body2">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
						</Box>
					</Box>
				))}
			</Box>
		) : (
			''
		);
	};

	const plusOptions = () => {
		const pkg = result && result.find((p) => p.en.toLowerCase() === 'plus');
		const proPackage = result && result.find((p) => p.en.toLowerCase() === 'pro');
		const notIcludedFeatures = getNotIncludedFeatures(pkg, proPackage);
		return pkg && pkg.plan_options ? (
			<Box display="flex" flexDirection="column" justifyContent="flex-start" color="#909090">
				{pkg.plan_options
					.sort((a, b) => {
						if (a.optionID && b.optionID) {
							return Number(a.optionID) > Number(b.optionID) ? 1 : Number(b.optionID) > Number(a.optionID) ? -1 : 0;
						}
						return 0;
					})
					.map((option, index) => (
						<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
							<Box m="6px">
								<CheckMark />
							</Box>
							<Box m="5px">
								<Typography variant="body1">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
							</Box>
						</Box>
					))}
				{notIcludedFeatures.map((option) => (
					<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
						<Box m="6px">
							<GradeOutCheckMark />
						</Box>
						<Box m="5px">
							<Typography variant="body2">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
						</Box>
					</Box>
				))}
			</Box>
		) : (
			''
		);
	};
	const proOptions = () => {
		const pkg = result && result.find((p) => p.en.toLowerCase() === 'pro');
		return pkg && pkg.plan_options ? (
			<Box display="flex" flexDirection="column" justifyContent="flex-start" color="#909090">
				{pkg.plan_options
					.sort((a, b) => {
						if (a.optionID && b.optionID) {
							return Number(a.optionID) > Number(b.optionID) ? 1 : Number(b.optionID) > Number(a.optionID) ? -1 : 0;
						}
						return 0;
					})
					.map((option, index) => (
						<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
							<Box m="6px">
								<CheckMark />
							</Box>
							<Box m="5px">
								<Typography variant="body2">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
							</Box>
						</Box>
					))}
			</Box>
		) : (
			''
		);
	};

	const freeOptions = () => {
		const pkg = result && result.find((p) => p.en.toLowerCase() === 'free');
		const proPackage = result && result.find((p) => p.en.toLowerCase() === 'pro');
		const notIcludedFeatures = getNotIncludedFeatures(pkg, proPackage);
		return pkg && pkg.plan_options ? (
			<Box display="flex" flexDirection="column" justifyContent="flex-start" color="#909090">
				{pkg.plan_options
					.sort((a, b) => {
						if (a.optionID && b.optionID) {
							return Number(a.optionID) > Number(b.optionID) ? 1 : Number(b.optionID) > Number(a.optionID) ? -1 : 0;
						}
						return 0;
					})
					.map((option, index) => (
						<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
							<Box m="6px">
								<CheckMark />
							</Box>
							<Box m="5px">
								<Typography variant="body1">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
							</Box>
						</Box>
					))}
				{notIcludedFeatures.map((option) => (
					<Box key={option.option_title_en} display="flex" flexDirection="row" justifyContent="flex-start">
						<Box m="6px">
							<GradeOutCheckMark />
						</Box>
						<Box m="5px">
							<Typography variant="body2">{t('lang') === 'en' ? option.option_title_en : option.option_title_ar}</Typography>
						</Box>
					</Box>
				))}
			</Box>
		) : (
			''
		);
	};

	const plansOptions = {
		free: freeOptions,
		starter: starterOptions,
		plus: plusOptions,
		pro: proOptions,
		lite: liteOptions,
		soft: softOptions,
		danatalkhaleej: DanatAlkhaleejOptions,
		plus2years: plusOptions,
	};

	const backButton = (
		<Button variant="outlined" onClick={() => history.goBack()} style={{ color: 'white', borderColor: 'white', fontWeight: 'bold' }}>
			{t('lang') === 'en' ? <ArrowBack /> : <ArrowForward />}
		</Button>
	);

	const cards =
		result === null ? (
			''
		) : (
			<AppContainer>
				<Grid container spacing={1} direction="column">
					<Grid item>{backButton}</Grid>
					<AppPaper>
						<Box m={3}>
							<Grid item>
								<Box justifyContent="center" display="flex" flexDirection="row" flexWrap="wrap" style={{ overflow: 'auto' }}>
									{result.map((plan, index) => {
										return (
											<Grow key={plan.en} in timeout={500 * index}>
												<Link
													style={{ textDecoration: 'none' }}
													to={
														planName && clientId && branchId
															? `/subscribe/${plan.en.toLowerCase()}/${clientId}/${branchId}`
															: `/subscribe/${plan.en.toLowerCase()}`
													}
												>
													<Box m={1.25} minWidth="260px" width="270px" height="490px">
														<Card style={{ height: '100%', overflow: 'auto' }} className="card">
															<Box p="16px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
																<Typography style={{ color: 'rgb(55 55 55 / 87%)' }} variant="h5">
																	{t('lang') === 'en'
																		? plan.enDisplayName
																			? plan.enDisplayName
																			: plan.en
																		: plan.arDisplayName
																			? plan.arDisplayName
																			: plan.ar}
																</Typography>
																<Box borderRadius="4px" borderBottom="1px solid #80808033" marginBottom="14px" marginTop="14px" width="100%" />
																<Box dir="ltr">
																	<Price
																		yearly={plan.en.toLowerCase() === 'free' ? 'Free' : plan.yearly_price}
																		save={plan.saving}
																		years={plan.years}
																		days={plan.days}
																	/>
																</Box>
																<Box display="flex" flexDirection="column" justifyContent="flex-start">
																	{plansOptions[plan.en.toLowerCase()]}
																</Box>
																<Box display="flex">
																	<Button variant="outlined">{t('subscribe')}</Button>
																</Box>
															</Box>
														</Card>
													</Box>
												</Link>
											</Grow>
										);
									})}
								</Box>
							</Grid>
						</Box>
					</AppPaper>
				</Grid>
			</AppContainer>
		);

	useEffect(() => {
		document.title = `${title} | ${company}`;
		fetch('https://us-central1-lazywaitposcordova.cloudfunctions.net/app/plans')
			.then((res) => res.json())
			.then((res) => {
				setResult(sortResult(res));

				setLoaded(true);
			})
			.catch((error) => console.log('error', error));
		return () => {
			console.log('cleanup');
		};
	}, [loaded, company, title]);
	// const theme = useTheme();
	// const matchesDownXS = useMediaQuery(theme.breakpoints.down("sm"));
	return result === null ? (
		<Box position="relative" m="32px" display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
			<CircularProgress style={{ color: 'white' }} color="inherit" />
		</Box>
	) : (
		<Box m="32px 10px 32px 10px" display="flex" flexDirection="column" alignItems="center">
			{/* <Box m="32px">
        <Typography variant="button">{t("select_plan")}</Typography>
      </Box> */}

			{cards}
		</Box>
	);
};
